/* eslint-disable no-plusplus */
import moment from 'moment';

export default class DateService {
  static getMomentDate(date: string) {
    return moment(date);
  }

  static getYYYYMMDDFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  static sortByDate(left: number, right: number) {
    return moment.utc(left).diff(moment.utc(right));
  }

  static getStartOfTheDayISO(date?: string) {
    return date ? moment(date).startOf('day').toISOString() : moment().startOf('day').toISOString();
  }

  static getEndOfTheDayISO(date?: string) {
    return date ? moment(date).endOf('day').toISOString() : moment().endOf('day').toISOString();
  }

  static subtractDaysFromDateISO(date: string | null, days: number) {
    return date ? moment(date).subtract(days, 'days').toISOString() : moment().subtract(days, 'days').toISOString();
  }

  static addDaysToDateISO(date: string | null, days: number) {
    return date ? moment(date).add(days, 'days').toISOString() : moment().add(days, 'days').toISOString();
  }

  static subtractMonthsFromDateISO(date: string | null, months: number) {
    return date
      ? moment(date).subtract(months, 'months').toISOString()
      : moment().subtract(months, 'months').toISOString();
  }

  static addMonthsToDateISO(date: string | null, months: number) {
    return date ? moment(date).add(months, 'months').toISOString() : moment().add(months, 'months').toISOString();
  }

  static getStartOfTheMonthISO(date?: string) {
    return date ? moment(date).startOf('month').toISOString() : moment().startOf('month').toISOString();
  }

  static getEndOfTheMonthISO(date?: string) {
    return date ? moment(date).endOf('month').toISOString() : moment().endOf('month').toISOString();
  }

  static format(date: string, format: string) {
    return moment(date).format(format);
  }

  static getDaysOfWeek() {
    const weekStart = moment().startOf('week');
    const days = [];
    for (let i = 1; i <= 7; i++) {
      days.push({
        id: i,
        name: weekStart.clone().add(i, 'days').format('dddd'),
      });
    }
    return days;
  }

  static getDaysOfMonth() {
    const days = [];
    for (let i = 1; i <= 31; i++) {
      days.push({
        id: i,
        name: i,
      });
    }
    return days;
  }
}
