/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Transition, Menu } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import HopperServiceApiProvider from '../../../services/hopperServiceApiProvider';
import { updateEntity, resetSelectedEntityState, deleteSingleDisplayEntity } from '../../../redux/slices/database';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { notify } from '../../../components/shared/Notification';
import { ServerError } from '../../../redux/slices/core.models';
import { Organisation } from '../../../redux/models/database.model';
import { getInitalValues } from '../../../redux/slices/database.entity.utils';
import { Entity, Group } from '../../../redux/models/auth.models';
import SingleInfoModalElement from '../../../tables/Modal/SingleInfoModalElement';
import { selectGroupsList } from '../../../redux/slices/groups';
import { buildBreadcrumbs } from '../entitydetails.utils';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import { PermissionsDropdown } from '../../../components/shared/DropdownSelect';
import classNames from '../../../utils/tailwind';
import DeleteModal from '../../../components/modals/DeleteModal';
import { getCollection } from '../../../redux/slices/database.utils';

interface Props {
  baseUrl: string;
  collectionPath: string;
  entity: Entity;
  editable: boolean;
  organisation: Organisation;
  closeDetailView: () => void;
}

// Add selected entity to redux
function EntityDetailsContainer({ baseUrl, collectionPath, entity, editable, organisation, closeDetailView }: Props) {
  const dispatch = useAppDispatch();
  const collection = getCollection(organisation, collectionPath);
  const elements = collection?.elements || [];
  const vals = entity ? getInitalValues(entity, elements) : {};
  const groups = useSelector(selectGroupsList);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [initialValues, setInitialValues] = useState(vals);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeletingEntity] = useState(false);
  const pages = buildBreadcrumbs(organisation, collectionPath, entity);
  const entityEndpoint = `${baseUrl}/${collectionPath}/${entity.entity_id}`;
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!isEqual(initialValues, values) && entity) {
        const updatedEntityData = { ...entity, data: values };
        try {
          const response = await HopperServiceApiProvider.updateEntity(entityEndpoint, updatedEntityData);
          if (response.status < 300) {
            const { data } = response;
            const updatedValues = getInitalValues(data.data, elements);
            setInitialValues(updatedValues);
            dispatch(updateEntity(data.data));
          } else {
            if (response.status >= 500) {
              const error = ServerError;
              notify("Couldn't update item", error.message, 'error');
            } else {
              notify("Couldn't update item", '', 'error');
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            const errorMessage = `Unable to update to items to Database`;
            // eslint-disable-next-line no-console
            console.error(errorMessage);
          }
        }
      }
    },
  });

  const handleConfirmDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteEntity = async () => {
    try {
      await HopperServiceApiProvider.deleteSingleEntityItem(entityEndpoint);
      dispatch(deleteSingleDisplayEntity(entity.entity_id));
      closeDetailView();
    } catch (error) {
      const errorMessage = `Unable to delete record from the database`;
      notify(errorMessage, 'Please contact support if the issue persists', 'error');
    }
  };
  const handleAddGroup = (group: Group) => {
    if (group) {
      setSelectedGroup(group);
      formik.setFieldValue('role_ids', [group.id]);
      formik.handleSubmit();
    }
  };

  const [roleId] = formik.values.role_ids || [];

  useEffect(() => {
    if (roleId && groups) {
      const firstGroup = groups.find((group) => group.id === roleId);
      if (firstGroup) {
        setSelectedGroup(firstGroup);
      }
    }
  }, [roleId, groups]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedEntityState({}));
    };
  }, [dispatch]);
  return (
    <div>
      <div className="flex justify-between">
        <Breadcrumbs pages={pages} />
        <div>
          {editable && (
            <Menu as="div" className="relative text-left mr-4 flex min-w-[150px] justify-between">
              <PermissionsDropdown
                dataCy="edit_entity_modal"
                setPermissionSelected={handleAddGroup}
                permissionSelected={selectedGroup}
              />
              {editable ? (
                <>
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white ml-2 px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-100">
                      <span className="sr-only">Options</span>
                      <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block px-4 py-2 text-sm w-full'
                              )}
                            >
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                onClick={handleConfirmDelete}
                              >
                                Delete
                              </button>
                            </div>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </>
              ) : null}
            </Menu>
          )}
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="pt-6">
          {elements.length &&
            entity &&
            elements.map((element) => (
              <SingleInfoModalElement
                entityId={entity.entity_id}
                key={element.name}
                element={element}
                formik={formik}
                elements={elements}
                editable={editable}
              />
            ))}
        </div>
      </form>
      <DeleteModal
        title="Delete record?"
        details="Are you sure you'd like to delete this record?"
        data={entity}
        open={deleteModalOpen}
        isDeleting={isDeletingEntity}
        setOpen={setDeleteModalOpen}
        onDelete={handleDeleteEntity}
      />
    </div>
  );
}

export default EntityDetailsContainer;
