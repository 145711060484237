/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  width?: string;
  minWidth?: string;
  formikValue: any;
  placeholder?: string;
  editiable: boolean;
  setFormikValue: any;
  formikProperty: any;
  submitFormik: any;
}

function TableCell({
  width,
  minWidth,
  formikValue,
  editiable,
  setFormikValue,
  placeholder,
  formikProperty,
  submitFormik,
}: Props) {
  const input = React.useRef<HTMLInputElement>(null);
  const [isInput, setIsInput] = useState(false);

  const handleClick = () => {
    if (editiable) {
      setIsInput(true);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const typedInput = e.currentTarget.value;
    setFormikValue(formikProperty, typedInput);
    if (e.key === 'Enter' && formikValue !== '') {
      submitFormik();
      setIsInput(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const typedInput = e.currentTarget.value;
    setFormikValue(formikProperty, typedInput);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const typedInput = e.target.value;
    setFormikValue(formikProperty, typedInput);
    submitFormik();
    setIsInput(false);
  };

  return (
    <div className="table-cell-row relative hover:border-2 hover:border-cell-border" style={{ minWidth, width }}>
      <div
        onClick={handleClick}
        className={`table-inner-cell ${isInput ? 'w-full' : 'hover:border-2 hover:border-cell-border'}`}
      >
        {!isInput && (
          <span className="flex items-center hover:bg-white w-full break-words text-cell-input-text text-sm font-normal min-h-[25px] min-w-[25px] text-ellipsis overflow-hidden truncate">
            {formikValue}
          </span>
        )}
        {isInput && editiable && (
          <input
            ref={input}
            className="w-full break-words text-sm outline-0"
            type="text"
            placeholder={placeholder}
            defaultValue={formikValue || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        )}
      </div>
    </div>
  );
}

TableCell.defaultProps = {
  width: '100%',
  minWidth: '100%',
  placeholder: '',
};

export default TableCell;
