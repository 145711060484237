/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { CollectionElement } from '../../redux/models/database.model';
import { CellInput, DropdownCell } from '../Form/Input';
import TableMultiRelCell from '../Body/Cells/TableRelCell/TableMultiRelCell';
import TableRelCell from '../Body/Cells/TableRelCell/TableRelCell';
import TableDateTimeCell from '../Body/Cells/TableDateTimeCell/TableDateTimeCell';
import TableAttachmentRow from '../Body/Cells/TableAttachmentCell/TableAttachmentRow';
import MultiCellInput from '../Form/Inputs/MultiCellInput';
import MultiAttachmentRow from '../Body/Cells/MultiAttachment/MultiAttachmentRow';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import CheckboxCell from '../Body/Cells/CheckboxCell/CheckboxCell';

interface Props {
  ele: CollectionElement;
  elements: CollectionElement[];
  resetArr: boolean;
  formik: any;
  setResetArr: React.Dispatch<React.SetStateAction<boolean>>;
}

const isEqual = (a: CollectionElement, b: CollectionElement) => {
  return a.type === b.type && a.name === b.name && a.position === b.position && a.property === b.property;
};

export default function AddEntityInput({ ele, elements, resetArr, setResetArr, formik }: Props) {
  const width = '100%';
  const key = ele.property;
  const { datetime, file, multi_file, multi_rel, multi_role, multi_select, multi_string, rel, role, select, bool } =
    ELEMENT_TYPE_ID;
  if (ele.type === multi_string)
    return (
      <MultiCellInput
        reset={resetArr}
        setReset={setResetArr}
        setFormikValue={formik.setFieldValue}
        placeholder={ele.name}
        name={ele.property}
        type={ele.type}
        width={width}
        minWidth={width}
      />
    );

  if (ele.type === multi_select || ele.type === multi_role) {
    const element = elements.find((col) => isEqual(col, ele));
    const options = element?.options || [];
    return (
      <DropdownCell
        options={options}
        reset={resetArr}
        setReset={setResetArr}
        placeholder={ele.name}
        name={ele.property}
        formikValue={formik.values[ele.property]}
        key={ele.property}
        setFormikValue={formik.setFieldValue}
        isForm
        width={width}
        minWidth={width}
        single={false}
      />
    );
  }
  if (ele.type === select || ele.type === role) {
    const element = elements.find((col) => isEqual(col, ele));
    const options = element?.options || [];
    return (
      <DropdownCell
        options={options}
        reset={resetArr}
        setReset={setResetArr}
        placeholder={ele.name}
        name={ele.property}
        formikValue={formik.values[ele.property]}
        key={ele.property}
        setFormikValue={formik.setFieldValue}
        single
        isForm
        width={width}
        minWidth={width}
      />
    );
  }
  if (ele.type === multi_rel) {
    return (
      <TableMultiRelCell
        element={ele}
        submitFormik={formik.handleSubmit}
        formikProperty={ele.property}
        setFormikValue={formik.setFieldValue}
        formikValue={formik.values[ele.property]}
        key={ele.property}
        isForm
        editiable
        width={width}
        minWidth={width}
      />
    );
  }
  if (ele.type === rel) {
    return (
      <TableRelCell
        element={ele}
        submitFormik={formik.handleSubmit}
        formikProperty={ele.property}
        setFormikValue={formik.setFieldValue}
        formikValue={formik.values[ele.property]}
        key={ele.property}
        isForm
        editiable
        width={width}
        minWidth={width}
      />
    );
  }

  if (ele.type === datetime) {
    return (
      <TableDateTimeCell
        element={ele}
        submitFormik={formik.handleSubmit}
        formikProperty={ele.property}
        setFormikValue={formik.setFieldValue}
        formikValue={formik.values[ele.property]}
        key={ele.property}
        isForm
        editable
        width={width}
        minWidth={width}
      />
    );
  }
  if (ele.type === file) {
    return (
      <TableAttachmentRow
        queueId={`new-entity-single-upload-${ele.property}`}
        submitFormik={formik.handleSubmit}
        formikProperty={key}
        setFormikValue={formik.setFieldValue}
        file={formik.values[key] || {}}
        key={key}
        isModal
        isForm
        editable
        width={width}
        minWidth={width}
      />
    );
  }
  if (ele.type === multi_file) {
    return (
      <MultiAttachmentRow
        queueId={`new-entity-multi-upload-${ele.property}`}
        submitFormik={formik.handleSubmit}
        formikProperty={key}
        setFormikValue={formik.setFieldValue}
        formikValue={formik.values[key] || []}
        key={key}
        isForm
        isModal
        editable
        width={width}
        minWidth={width}
      />
    );
  }
  if (ele.type === bool) {
    return (
      <CheckboxCell
        placeholder={ele.name}
        key={ele.name}
        formikValue={formik.values[ele.property]}
        width={width}
        minWidth={width}
        editable
        setFormikValue={formik.setFieldValue}
        formikProperty={ele.property}
        submitFormik={() => {}}
      />
    );
  }
  return (
    <CellInput
      placeholder={ele.name}
      name={ele.property}
      type={ele.type}
      key={ele.name}
      formikValue={formik.values[ele.property]}
      required={ele.required}
      formikChange={formik.handleChange}
      width={width}
      minWidth={width}
    />
  );
}
