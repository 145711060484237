/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectCollectionUrl,
  selectSelectedEntity,
  loadEntityById,
  resetSelectedEntityState,
} from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import { CollectionElement } from '../../redux/models/database.model';
import EditEntityModal from './EditEntityModal';

interface Props {
  entityId: string;
  elements: CollectionElement[];
  isOpen: boolean;
  onClose: () => void;
}

// Add selected entity to redux
function EditEntityContainer({ entityId, elements, isOpen, onClose }: Props) {
  const dispatch = useAppDispatch();
  const collectionUrl = useSelector(selectCollectionUrl);
  const entity = useSelector(selectSelectedEntity);

  useEffect(() => {
    if (!entity) {
      dispatch(loadEntityById(`${collectionUrl}/${entityId}`));
    }
  }, [entity, entityId, collectionUrl, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetSelectedEntityState(null));
    };
  }, [dispatch]);

  return entity && <EditEntityModal entity={entity} elements={elements} isOpen={isOpen} onClose={onClose} />;
}

export default EditEntityContainer;
