/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import DateService from '../../../../utils/dateService';
import { CollectionElement } from '../../../../redux/models/database.model';

interface Props {
  element: CollectionElement;
  width?: string;
  formikValue: any;
  minWidth?: string;
  setFormikValue: any;
  formikProperty: any;
  submitFormik: any;
  isForm?: boolean;
  showPlaceholder?: boolean;
  editable: boolean;
}

const getLabel = (element: CollectionElement, value: any, format: string, showPlaceholder: boolean) => {
  if (!showPlaceholder && !value) {
    return '';
  }
  const placeholder = element.format ? element.format : 'No date set';
  return value ? DateService.format(value, format) : placeholder;
};

function TableDateTimeCell({
  element,
  width,
  formikValue,
  minWidth,
  setFormikValue,
  formikProperty,
  submitFormik,
  showPlaceholder = true,
  isForm = false,
  editable,
}: Props) {
  const [isInput, setIsInput] = useState(false);
  const handleClick = () => {
    if (editable) {
      setIsInput(true);
    }
  };
  const handleDateChange = (date: any) => {
    setFormikValue(formikProperty, date);
    if (!isForm) {
      submitFormik();
    }
  };

  const getMoveDate = (move: string) => (move ? new Date(move) : null);
  const format = element.format || 'YYYY-MM-dd';
  const isTime = format.includes('hh:mm') || format.includes('h:mm');
  const inputFormat = isTime ? 'hh:mm: a' : 'yyyy-MM-dd';
  const label = getLabel(element, formikValue, format, showPlaceholder);
  return (
    <div
      className="table-cell-row relative hover:border-2 hover:border-cell-border hover:cursor-pointer"
      style={{ width, minWidth }}
    >
      <div
        onClick={handleClick}
        className={`table-inner-cell hover:cursor-pointer ${
          isInput ? 'w-full' : 'hover:border-2 hover:border-cell-border'
        }`}
      >
        {!isInput && (
          <span className="hover:cursor-pointer hover:bg-white w-full break-words text-cell-input-text text-sm min-h-[25px] min-w-[25px] flex items-center">
            {label}
          </span>
        )}
        {isInput && (
          <div className="z-30">
            <DatePicker
              className="outline-0 hover:cursor-pointer"
              showTimeSelect
              showMonthDropdown
              showYearDropdown
              dateFormat={inputFormat}
              selected={getMoveDate(formikValue)}
              onChange={handleDateChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}

TableDateTimeCell.defaultProps = {
  width: '100%',
  minWidth: '100%',
  isForm: false,
  showPlaceholder: true,
};

export default TableDateTimeCell;
