/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { ArrowsPointingOutIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

import { FileValues } from '../../TableCell.modals';
import Spinner from '../../../../components/shared/Spinner';
import AttachmentModal from './AttachmentModal';
import FileApi from './FileApi';
import { Queue } from './attachment.utils';
import { notify } from '../../../../components/shared/Notification';
import TableAttachmentCellItem from './TableAttachmentCellItem';

interface Props {
  queueId: string;
  formikValue: any;
  setFormikValue: any;
  formikProperty: any;
  editiable: boolean;
  width: string;
  minWidth: string;
}

function TableMultiAttachmentCell({
  queueId,
  formikValue,
  setFormikValue,
  formikProperty,
  editiable,
  width,
  minWidth,
}: Props) {
  const [showPopup, setShowPopup] = useState(false);
  const handleClick = async (file: FileValues) => {
    try {
      const downloadedFile = await FileApi.downloadFile(file);
      Queue.addPath(queueId, downloadedFile.path || '');
      const url = downloadedFile.path;
      if (url) window.open(url, '_blank');
    } catch (error) {
      notify('Error downloading file', 'Please contact support if the problem persists', 'error');
    }
  };

  const handlePopupClick = () => {
    setShowPopup(true);
  };

  const handleXClick = (idx: number) => {
    const confirmResult = window.confirm('Are you sure you want to delete the file?');
    if (confirmResult) {
      setFormikValue(
        formikProperty,
        formikValue.filter((ele: string, index: number) => index !== idx)
      );
    }
  };

  return (
    <div className="table-cell-row relative flex align-middle" style={{ width, minWidth }}>
      {formikValue.length !== 0 && formikValue.length ? (
        <div className="table-inner-cell hover:cursor-pointer flex align-middle  overflow-hidden">
          <div className="flex items-start">
            {formikValue?.slice(0, 3).map((file: FileValues, idx: number) => (
              <TableAttachmentCellItem key={`table-attachment-cell-item-${idx}`} file={file} queueId={queueId} />
            ))}
            {formikValue?.length > 3 && (
              <button
                type="button"
                onClick={handlePopupClick}
                className="inline-flex h-6 w-6 p-1 justify-center items-center gap-x-1.5 rounded-md bg-white text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 hover:text-blue-600"
              >
                <EllipsisHorizontalIcon className="w-4 h-4" />
              </button>
            )}
            <div className="mr-4 popup-button-container">
              <button
                type="button"
                onClick={handlePopupClick}
                className="inline-flex h-6 w-6 justify-center items-center gap-x-1.5 rounded-md bg-white text-sm font-semibold text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 hover:text-blue-600"
              >
                <ArrowsPointingOutIcon className="w-4 h-4" />
              </button>
            </div>
            {showPopup && (
              <AttachmentModal
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                formikValue={formikValue}
                editable={editiable}
                handleXClick={handleXClick}
                handleClick={handleClick}
              />
            )}
          </div>
        </div>
      ) : (
        Object.keys(formikValue).length !== 0 && <Spinner size="small" />
      )}
    </div>
  );
}

export default TableMultiAttachmentCell;
