/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ELEMENT_TYPES, ELEMENT_TYPE_ID } from './element.models';
import {
  CollectionElement,
  DatabaseState,
  Organisation,
  TableData,
  FooterItem,
  FooterCalculation,
  CollectionGroupResponse,
  CollectionListResponse,
  RelsContainer,
  CollectionData,
} from '../models/database.model';
import { Entity } from '../models/auth.models';
import { format as formatter } from '../../utils/formatter.numbers';
import Calculations from '../../utils/calculations';
import { DataModel, EntityContainer } from './core.models';
import { SidebarItems } from '../../components/sidebar/Sidebar.model';
import { CollectionRequest } from './collection.models';
import DateService from '../../utils/dateService';
import { App } from '../models/app.models';

export const getCollection = (org: Organisation, collectionId: string) => {
  const apps = org?.data?.apps || [];
  const cols = apps.flatMap((app) => app.data.collections) || [];
  return cols.find((collection) => collection.entity_id === collectionId);
};

export const getBaseUrl = (org: Organisation, col: CollectionData) => {
  const apps = org?.data?.apps || [];
  return apps.find((app) => {
    const entityId = col?.entity_id || '';
    return app.data.collections.find((appColl) => appColl.entity_id === entityId);
  })?.data.base_url;
};

export const getEndpoint = (baseUrl: string, col: CollectionData) => `${baseUrl}${col.path}`;

export const setColumnsAndFilterOptions = (organisation: Organisation, collectionId: string) => {
  const collections = organisation?.data.apps.flatMap((app) => app.data.collections) || [];
  const column = collections.find((col) => col.entity_id === collectionId);
  return column;
};

export const checkRel = (entities: CollectionElement[]) => {
  return entities.some((ele) => ele.type === ELEMENT_TYPE_ID.rel || ele.type === ELEMENT_TYPE_ID.multi_rel);
};

export const setRel = (entities: CollectionElement[], organisation: Organisation): EntityContainer<RelsContainer> => {
  const relEntities = entities
    .filter((entitiesEle) => entitiesEle.collection_id !== null)
    .map((entitiesEle) => {
      return organisation.data.apps
        .flatMap((app) => app.data.collections)
        .find((collectionEle) => collectionEle.entity_id === entitiesEle.collection_id);
    });
  return relEntities.reduce((entityObj, collection) => {
    const matchingEntity = entities.find((entitVal) => entitVal.collection_id === collection?.entity_id);
    const displayName = matchingEntity?.element_display_prop || '';
    const collectionId = collection?.entity_id || '';
    return {
      ...entityObj,
      [collectionId]: { value: collection, dataLoaded: false, displayName, data: [] },
    };
  }, {});
};

export const createSidebarData = (org: Organisation) => {
  const collections = org.data.apps.flatMap((app) => app.data.collections) || [];
  const baseUrl = org.data.base_url || '';
  const items: SidebarItems[] = collections
    .filter((item) => item.visible)
    .map((collection) => {
      return {
        id: collection.entity_id,
        title: collection.name,
        baseURL: baseUrl,
        path: `/${collection.entity_id}`,
        resourceId: collection.resource_id || '',
      };
    });
  return items;
};

// =========================================== Table Footer Methods ===========================================

const createCalculations = (element: CollectionElement) => {
  const isFooter = ELEMENT_TYPES[element.type]?.isFooter || false;
  const calcs: FooterCalculation[] = [
    {
      name: 'None',
      id: 'none',
    },
    {
      name: 'Sum',
      id: 'sum',
    },
  ];
  return isFooter ? calcs : [];
};

export const createEmptyFooter = (elements: CollectionElement[]) => {
  const footer: FooterItem[] = elements.map((element) => {
    const isFooter = ELEMENT_TYPES[element.type]?.isFooter || false;
    const title = isFooter ? 'Calculate' : '';
    return {
      title,
      detail: '',
      isActive: false,
      isSupported: isFooter,
      calculations: createCalculations(element),
    };
  });
  return footer;
};

const entityGroupFilter = (entity: Entity, element: CollectionElement, val: string | null) => {
  const { data } = entity;
  const { type, property } = element;
  const { isEmbedded } = ELEMENT_TYPES[type];
  if (isEmbedded) {
    const prop = element.element_display_prop ?? '';
    const isValue = Boolean(data?.[property]?.data?.[prop] ?? false);
    return isValue ? data?.[property]?.data?.[prop] === val : false;
  }
  return entity.data[element.property] === val;
};

export const sumRowData = (item: CollectionElement, rows: any) => {
  const { precision, format } = item;
  const total = Calculations.sum(item, rows);
  if (format) {
    return formatter(total, format, precision || 2);
  }
  return total.toFixed(2);
};

export const validGroupByProperty = (item: CollectionElement) => {
  return ELEMENT_TYPES[item.type]?.isGroup;
};

// =========================================== Entity Methods ===========================================

// export const getEntityDisplayValue = (entity: Entity, element: CollectionElement): string => {
//   if (ELEMENT_TYPES[element.type].isEmbedded) {
//     const prop = element.element_display_prop || '';
//     if (
//       entity.data?.hasOwnProperty(element.property) &&
//       entity.data[element.property] !== null &&
//       entity.data[element.property].data &&
//       entity.data[element.property]?.hasOwnProperty(prop)
//     ) {
//       return entity.data[element.property].data[prop];
//     }
//     return '';
//   }
//   return entity.data[element.property];
// };

const getEnityDateValue = (element: CollectionElement, value: any, format: string, showPlaceholder: boolean) => {
  if (!showPlaceholder && !value) {
    return '';
  }
  const placeholder = element.format ? element.format : 'No date set';
  return value ? DateService.format(value, format) : placeholder;
};

const getSelectName = (selectId: string, element: CollectionElement) => {
  const options = element.options || [];
  return options.find((option) => option.id === selectId)?.name || '';
};

export const getEntityDisplayValue = (entity: Entity, element: CollectionElement): string => {
  if (ELEMENT_TYPES[element.type].isEmbedded) {
    const prop = element.element_display_prop || '';
    if (
      entity.data?.hasOwnProperty(element.property) &&
      entity.data[element.property] !== null &&
      entity.data[element.property].data &&
      entity.data[element.property].data?.hasOwnProperty(prop)
    ) {
      return entity.data[element.property].data[prop];
    }
    return '';
  }
  const value = entity.data[element.property] || '';
  if (element.type === ELEMENT_TYPE_ID.datetime) {
    const format = element.format || 'YYYY-MM-DD';
    return getEnityDateValue(element, value, format, true);
  }
  if (element.type === ELEMENT_TYPE_ID.select || element.type === ELEMENT_TYPE_ID.multi_select) {
    const selectId = entity.data[element.property];
    return getSelectName(selectId, element);
  }
  return entity.data[element.property];
};

export const getEntityValue = (entity: Entity, element: CollectionElement | undefined, joinArr = false): string => {
  if (!element) return '';
  if (ELEMENT_TYPES[element.type].isEmbedded) {
    const placeholder = `${element.name} not set`;
    const prop = element.element_display_prop || placeholder;
    if (entity.data?.hasOwnProperty(prop) && entity.data[prop] !== null) {
      return entity.data[prop];
    }
    return placeholder;
  }
  const value = entity.data[element.property] || '';
  if (element.type === ELEMENT_TYPE_ID.datetime) {
    const format = element.format || 'YYYY-MM-DD';
    return getEnityDateValue(element, value, format, true);
  }
  if (element.type === ELEMENT_TYPE_ID.select || element.type === ELEMENT_TYPE_ID.role) {
    const selectId = entity.data[element.property];
    return getSelectName(selectId, element);
  }
  if (element.type === ELEMENT_TYPE_ID.multi_select || element.type === ELEMENT_TYPE_ID.multi_role) {
    const selectIds = entity.data[element.property] || [];
    const selectedOptions = selectIds.map((id: string) => getSelectName(id, element));
    return joinArr ? selectedOptions.join(',') : selectedOptions;
  }
  if (element.type === ELEMENT_TYPE_ID.file || element.type === ELEMENT_TYPE_ID.multi_file) {
    return '';
  }
  return value;
};

export const getCsvEntityValue = (entity: Entity, element: CollectionElement | undefined, joinArr = false): string => {
  if (!element) return '';
  if (element.type === ELEMENT_TYPE_ID.rel) {
    const placeholder = `${element.name} not set`;
    const prop = element.property || placeholder;
    if (entity.data?.hasOwnProperty(prop) && entity.data[prop] !== null) {
      const rel = entity.data[prop];
      return getEntityValue(rel, element);
    }
    return placeholder;
  }
  if (element.type === ELEMENT_TYPE_ID.multi_rel) {
    const placeholder = `${element.name} not set`;
    const prop = element.property || placeholder;
    if (entity.data?.hasOwnProperty(prop) && entity.data[prop] !== null) {
      const rels = entity.data[prop] || [];
      return rels.map((rel: Entity) => getEntityValue(rel, element));
    }
    return placeholder;
  }
  const value = entity.data[element.property] || '';
  if (element.type === ELEMENT_TYPE_ID.datetime) {
    const format = element.format || 'YYYY-MM-DD';
    return getEnityDateValue(element, value, format, true);
  }
  if (element.type === ELEMENT_TYPE_ID.select || element.type === ELEMENT_TYPE_ID.role) {
    const selectId = entity.data[element.property];
    return getSelectName(selectId, element);
  }
  if (element.type === ELEMENT_TYPE_ID.multi_select || element.type === ELEMENT_TYPE_ID.multi_role) {
    const selectIds = entity.data[element.property] || [];
    const selectedOptions = selectIds.map((id: string) => getSelectName(id, element));
    return joinArr ? selectedOptions.join(',') : selectedOptions;
  }
  if (element.type === ELEMENT_TYPE_ID.file || element.type === ELEMENT_TYPE_ID.multi_file) {
    return '';
  }
  return value;
};

// =========================================== Group Methods ===========================================

const getGroupNames = (data: DataModel<object>[], element: CollectionElement): EntityContainer<string> => {
  return data.reduce((prev, entity) => {
    const name = getEntityDisplayValue(entity, element);
    return {
      ...prev,
      [name]: name,
    };
  }, {});
};

export const getGroupData = (displayData: TableData, element: CollectionElement): TableData[] => {
  const { rows, columns } = displayData;
  // 1. get unique vals from rows
  const groupNames = getGroupNames(rows, element);
  // 2. filter rows by unique data
  return Object.values(groupNames)
    .sort((a: string, b: string) => {
      const aName = a || '';
      const bName = b || '';
      return aName.localeCompare(bName);
    })
    .map((title: string) => {
      const name = title !== '' ? title : `No ${element.name} set`;
      return {
        title: name,
        isOpen: true,
        columns: [...columns],
        rows: rows.filter((row) => entityGroupFilter(row, element, `${title}`)),
        footerRows: createEmptyFooter(displayData.columns),
      };
    });
};

/// Add entity to list view
export const addListEntity = (state: DatabaseState, entity: DataModel<object>) => {
  const entities = state.displayEntity.rows;
  entities.push(entity);
  return entities;
};

/// update entity in list view
export const updateListEntity = (state: DatabaseState, entity: DataModel<object>) => {
  return state.displayEntity.rows.map((item: DataModel<object>) => {
    if (item.entity_id === entity.entity_id) {
      return entity;
    }
    return item;
  });
};

export const addGroupedEntity = (state: DatabaseState, entities: DataModel<object>[]) => {
  if (state.request.grouping === undefined) {
    return null;
  }
  const table: TableData = {
    ...state.displayEntity,
    rows: entities,
    isOpen: false,
  };

  const element: CollectionElement = state.displayGroup.group || {
    collection_id: null,
    element_display_prop: null,
    format: null,
    name: '',
    options: null,
    position: 0,
    property: '',
    required: false,
    precision: null,
    rel_filter_prop: null,
    type: 'string',
  };
  return getGroupData(table, element);
};

export const getDefaultGroupBy = (elements: CollectionElement[]) => {
  if (!elements || !elements.length) return null;
  const { select } = ELEMENT_TYPE_ID;
  const selectEl = elements.find((el) => el.type === select);
  if (selectEl) return selectEl;
  // const nameEl = elements.find((el) => el.name.includes('name'));
  // if (nameEl) return nameEl;
  // const descriptionEl = elements.find((el) => el.name.includes('description'));
  // if (descriptionEl) return descriptionEl;
  // return elements[0];
  return null;
};

export const searchValues = (obj: any, searchItem: string): boolean => {
  return Object.values(obj).some((value: any) => {
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        return value.some((item: any) => searchValues(item, searchItem));
      }
      return searchValues(value, searchItem);
    }
    if (typeof value === 'string') {
      return value.toLowerCase().includes(searchItem.toLowerCase());
    }
    return false;
  });
};

export const getEntityDisplayName = (element: any, data: any) => {
  if (data === undefined) {
    return '+Select';
  }
  const { displayName } = element;
  const name = data?.[displayName] ?? 'No name';
  return name;
};

export const mergeListViewRows = (state: DatabaseState, data: CollectionListResponse): Entity[] => {
  return [...state.displayEntity.rows, ...data.items];
};

const getTableHeader = (item: CollectionListResponse, element: CollectionElement, rows: Entity[]) => {
  const placeholder = `${element.name} not set`;
  if (element.type === 'rel') {
    const [entity] = rows;
    if (entity) {
      return getEntityDisplayValue(entity, element);
    }
    return placeholder;
  }
  if (element.type === 'datetime' && element.format && item.id) {
    return DateService.format(item.id, element.format);
  }
  if (element.type === ELEMENT_TYPE_ID.select || element.type === ELEMENT_TYPE_ID.multi_select) {
    return item.id ? getSelectName(item.id, element) : placeholder;
  }
  return item.id || placeholder;
};

const createGroupedTableData = (
  columns: CollectionElement[],
  element: CollectionElement,
  item: CollectionListResponse
): TableData => {
  return {
    title: getTableHeader(item, element, item.items),
    isOpen: true,
    columns: [...columns],
    rows: item.items,
    footerRows: createEmptyFooter(columns),
  };
};

export const createGroupData = (
  displayData: TableData,
  element: CollectionElement,
  data: CollectionGroupResponse
): TableData[] => {
  const { columns } = displayData;
  return data.items.map((item) => createGroupedTableData(columns, element, item));
};

export const resetGroupedData = (state: DatabaseState, data: CollectionGroupResponse): TableData[] => {
  const { columns } = state.displayEntity;
  const element = state.displayGroup.group;
  if (!element) {
    return state.displayGroup.data;
  }
  return data.items.map((group) => createGroupedTableData(columns, element, group));
};

export const mergeGroupedRows = (state: DatabaseState, data: CollectionGroupResponse): TableData[] => {
  const { columns } = state.displayEntity;
  const element = state.displayGroup.group;
  if (!element) {
    return state.displayGroup.data;
  }
  const groups: EntityContainer<TableData> = state.displayGroup.data.reduce((store, group) => {
    return {
      ...store,
      [group.title]: group,
    };
  }, {});

  // new data that has an existing group
  const exisiting: EntityContainer<CollectionListResponse> = data.items.reduce((store, group) => {
    const name = getTableHeader(group, element, group.items);
    return {
      ...store,
      [name]: group,
    };
  }, {});

  // add exisiting to state
  const mergedGroup = state.displayGroup.data.map((group) => {
    if (exisiting[group.title]) {
      return {
        ...group,
        rows: [...group.rows, ...exisiting[group.title].items],
      };
    }
    return group;
  });

  // add new items to state
  const newGroups = data.items
    .filter((item) => {
      const name = getTableHeader(item, element, item.items);
      return item.id ? !groups[name] : false;
    })
    .map((group) => createGroupedTableData(columns, element, group));

  return [...mergedGroup, ...newGroups];
};

export const getCanLoadMore = (state: DatabaseState, filters: CollectionRequest) => {
  const totalCount = state.displayEntity.count || 0;
  const { grouping } = filters;
  if (grouping) {
    const items = state.displayGroup.data.flatMap(({ rows }) => rows).length;
    return items < totalCount;
  }
  return state.displayEntity.rows.length < totalCount;
};

export const getRelEntities = (elements: CollectionElement[], org: Organisation) => {
  const relIds = elements
    .filter((el) => el.type === ELEMENT_TYPE_ID.rel || el.type === ELEMENT_TYPE_ID.multi_rel)
    .map((el) => el.collection_id || '');

  const container: EntityContainer<App> = relIds.reduce((store, relId) => {
    return {
      ...store,
      [relId]: org.data.apps.find((app) => app.data.collections.find((col) => col.entity_id === relId)),
    };
  }, {});
  return container;
};
