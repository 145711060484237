/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  ColumnResizeMode,
  getFilteredRowModel,
  ColumnFiltersState,
  FilterFn,
} from '@tanstack/react-table';
import {
  RankingInfo,
  rankItem,
  // compareItems,
} from '@tanstack/match-sorter-utils';
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline';
import { isDisplayLoaded, isEditable, isTableFooter } from '../../redux/slices/database';
import { TableData } from '../../redux/models/database.model';
import EmptyState from '../../components/shared/EmptyState';
// import { searchValues } from '../../redux/slices/database.utils';
// import { Entity } from '../../redux/models/auth.models';
import './DataGrid.scss';
import DataCell from './components/DataCell';
import DataCellMenu from './components/DataCellMenu';
import classNames from '../../utils/tailwind';
import GroupedTableHeader from '../Body/GroupedTableContainer/GroupedTableHeader';
import TableFootRowItem from '../Body/Footer/TableFootRowItem';
import Calculations from '../../utils/calculations';

function NoData({ editable }: { editable: boolean }) {
  const title = editable ? 'Add a new item' : 'No results';
  const details = editable ? 'Get started by filling out the form below.' : 'Try changing your filters above';
  return (
    <div className="pt-8">
      <EmptyState title={title} message={details} />
    </div>
  );
}

interface Props {
  displayData: TableData;
  search: string;
  title?: string | null;
  isEmpty: boolean;
  onEdit: () => void;
  onExpand?: (item: TableData) => void;
}

// const getRows = (search: string, rows: Entity[]) => {
//   if (search === '') {
//     return rows;
//   }
//   return rows.filter((row) => searchValues(row, search));
// };

export const createColumns = (table: TableData, onEdit: () => void) => {
  const elements = table.columns || [];
  const footers = table.footerRows || [];
  const header = {
    accessorKey: 'entity_id',
    cell: (info: any) => {
      return <DataCellMenu index={info.row.index} entity={info.row.original} onEdit={onEdit} />;
    },
    header: () => <span />,
    footer: () => <div />,
  };
  const cols = elements
    .filter((el) => el.visible)
    .map((el, elIdx) => ({
      accessorKey: `data.${el.property}`,
      cell: (info: any) => {
        return <DataCell entity={info.row.original} col={el} columns={elements} />;
      },
      header: () => <span className="text-xs font-medium text-gray-500">{el.name.toLocaleUpperCase()}</span>,
      footer: () => {
        const handleCalculation = () => {
          const title = Calculations.sum(el, table.rows);
          return `${title.toFixed(2)}`;
        };
        return <TableFootRowItem item={footers[elIdx]} index={elIdx} onCalculate={handleCalculation} />;
      },
    }));
  return [header, ...cols];
};

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

function DataGrid({ title, displayData, search, isEmpty = false, onEdit, onExpand }: Props) {
  const displayLoaded = useSelector(isDisplayLoaded);
  const isFooter = useSelector(isTableFooter);
  const editable = useSelector(isEditable);
  const rows = displayData.rows || []; // getRows(search, displayData.rows);
  const visibleCols = createColumns(displayData, onEdit);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState(search);
  const isTitle = title !== null;
  const table = useReactTable({
    data: rows,
    columns: visibleCols,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    enableColumnResizing: true,
    columnResizeMode: 'onChange' as ColumnResizeMode,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <section className="my-4 mx-8 flow-root">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle px-8">
          {!isEmpty && title && (
            <GroupedTableHeader title={title} displayData={displayData} rows={rows} onExpand={onExpand} />
          )}
          <table className="min-w-full border-separate border-spacing-0">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="">
                  {headerGroup.headers.map((header, headerIdx) => {
                    const isLeftRounded = !isTitle && headerIdx === 0;
                    const isRightRounded = !isTitle && headerIdx === headerGroup.headers.length - 1;
                    return (
                      <th
                        key={header.id}
                        style={{ width: header.getSize() }}
                        scope="col"
                        className={`sticky ${isLeftRounded ? 'rounded-tl-lg' : ''} ${
                          isRightRounded ? 'rounded-tr-lg' : ''
                        } bg-gray-100 top-[145px] z-10 border-b border-gray-300 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8`}
                      >
                        <div className="flex justify-between">
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                          <div
                            className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            style={{
                              transform: header.column.getIsResizing()
                                ? `translateX(${table.getState().columnSizingInfo.deltaOffset})`
                                : '',
                            }}
                          >
                            <ArrowsRightLeftIcon className="h-4 w-4" aria-hidden="true" />
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="">
              {displayData.isOpen &&
                table.getRowModel().rows.map((row, rowIdx) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={classNames(
                          rowIdx !== rows.length ? 'border-b border-gray-200' : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                        )}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
            {displayLoaded && isFooter && !isEmpty && (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
          {isEmpty && displayLoaded && <NoData editable={editable} />}
        </div>
      </div>
    </section>
  );
}

DataGrid.defaultProps = {
  title: null,
  onExpand: () => {},
};

export default DataGrid;
