/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RelationShipListItem from './RelationshipListItem';
import { Entity } from '../../redux/models/auth.models';
import { RelsContainer } from '../../redux/models/database.model';

const noResults = 'No Results';

interface Props {
  rels: RelsContainer;
  handleSelect: (entity: Entity) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  values: Entity[];
  multi: boolean;
  multiValues: Entity[];
  displayName: string;
  handleXClick: (idx: number) => void;
}
function RelationShipListContainer({
  rels,
  handleSelect,
  setOpen,
  values,
  multi,
  multiValues,
  displayName,
  handleXClick,
}: Props) {
  const displayType = rels.displayName;
  const relData = multiValues || [];
  return (
    <ul className="">
      <p className="pb-3 text-xs italic text-gray-300">
        To find all the results with no &quot;{displayType}&quot; type &quot;val:empty&quot;
      </p>
      <div className="flex flex-wrap pb-5">
        {multiValues
          ? multiValues.map((entity: Entity, idx: number) => {
              const name = entity.data[displayName];
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`selected-multi-val-${entity.entity_id}-${idx}`}
                  className="break-words hover:border text-sm rounded-md bg-cell-content text-cell-input-text m-1 px-1 flex items-center"
                >
                  <p>{name}</p>
                  <FontAwesomeIcon
                    fixedWidth
                    height={24}
                    width={24}
                    icon={faXmark}
                    className="pr-1 cursor-pointer"
                    onClick={() => handleXClick(idx)}
                  />
                </div>
              );
            })
          : ''}
      </div>
      {values.length > 0 ? (
        values.map((entity) => {
          const isSelected = relData.some((val) => val.entity_id === entity.entity_id);
          return (
            <RelationShipListItem
              key={entity.entity_id}
              entity={entity}
              isSelected={isSelected}
              collection={rels.value}
              displayType={displayType}
              handleSelect={handleSelect}
              setOpen={setOpen}
              multi={multi}
            />
          );
        })
      ) : (
        <p>{noResults}</p>
      )}
    </ul>
  );
}

export default RelationShipListContainer;
