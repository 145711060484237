/* eslint-disable no-console */
import React, { Fragment } from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import { XMarkIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { PermissionsDropdown } from '../../components/shared/DropdownSelect';
import { Group } from '../../redux/models/auth.models';
import Spinner from '../../components/shared/Spinner';
import classNames from '../../utils/tailwind';

interface Props {
  open: boolean;
  title: string;
  children: React.ReactNode;
  selectedGroup: Group | null;
  isSaving: boolean;
  editable?: boolean;
  canDelete?: boolean;
  onAddGroup: (group: Group) => void;
  onDelete: () => Promise<void>;
  onClose: () => void;
  onSave: () => void;
}

function EntityModal({
  open,
  title,
  children,
  selectedGroup,
  editable,
  canDelete,
  isSaving,
  onSave,
  onAddGroup,
  onClose,
  onDelete,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center py-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 h-[95vh] min-w-[960px] overflow-scroll max-w-screen-lg">
                <div className="absolute top-0 right-0 pt-[1.5rem] pr-4 flex justify-between align-middle min-w-[250px] z-40" />
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:mt-0 sm:mx-4 sm:text-left w-full relative">
                    <div className="w-full pt-4 flex justify-between align-middle sticky top-0 bg-white z-10">
                      <Dialog.Title
                        as="h2"
                        className="text-xl leading-6 text-gray-900 pb-4"
                        data-cy={`entity_modal-${title}`}
                      >
                        {title}
                      </Dialog.Title>
                      <div className="flex w-56 justify-between">
                        {editable && (
                          <Menu as="div" className="relative text-left mr-4 flex min-w-[150px] justify-between">
                            {editable && (
                              <div>
                                <PermissionsDropdown
                                  dataCy="entity_modal"
                                  setPermissionSelected={onAddGroup}
                                  permissionSelected={selectedGroup}
                                />
                              </div>
                            )}
                            {canDelete ? (
                              <>
                                <div>
                                  <Menu.Button
                                    data-cy="entity_modal-options_menu-toggle_btn"
                                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white ml-2 px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-100"
                                  >
                                    <span className="sr-only">Options</span>
                                    <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
                                  </Menu.Button>
                                </div>
                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <div
                                            className={classNames(
                                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                              'block px-4 py-2 text-sm w-full'
                                            )}
                                          >
                                            <button
                                              type="button"
                                              data-cy="entity_modal-options_menu-delete_btn"
                                              className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                              onClick={onDelete}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        )}
                                      </Menu.Item>
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </>
                            ) : null}
                          </Menu>
                        )}
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={onClose}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-4 w-screen-lg">{children}</div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse sticky bottom-0 w-full z-10">
                  <button
                    type="button"
                    data-cy="entity_modal-save_btn"
                    className="mr-4 inline-flex w-full justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onSave}
                  >
                    Save {isSaving ? <Spinner size="small" className="ml-1" /> : null}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

EntityModal.defaultProps = {
  editable: false,
  canDelete: false,
};

export default EntityModal;
