/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { CollectionElement } from '../../redux/models/database.model';
import classNames from '../../utils/tailwind';
import TableCell from '../Body/Cells/TableCell/TableCell';
import MultiAttachmentRow from '../Body/Cells/MultiAttachment/MultiAttachmentRow';
import TableAttachmentRow from '../Body/Cells/TableAttachmentCell/TableAttachmentRow';
import TableRelCell from '../Body/Cells/TableRelCell/TableRelCell';
import TableMultiRelCell from '../Body/Cells/TableRelCell/TableMultiRelCell';
import TableDropdownCell from '../Body/Cells/TableDropdownCell/TableDropdownCell';
import TableMultiCell from '../Body/Cells/TableMultiCell/TableMultiCell';
import TableDateTimeCell from '../Body/Cells/TableDateTimeCell/TableDateTimeCell';
import { ELEMENT_TYPE_ID } from '../../redux/slices/element.models';
import CheckboxCell from '../Body/Cells/CheckboxCell/CheckboxCell';

function ElementLabel({ text }: { text: string }) {
  return <p className="whitespace-nowrap text-gray-500 text-sm mr-2 min-w-[20%]">{text || ''}</p>;
}

const containerClasses = 'py-2 flex items-center w-full border-b-2 border-gray-100 overflow-x-clip';
const attachmentContainerClasses = 'py-2 flex items-start w-full border-b-2 border-gray-100';
const isEqual = (a: CollectionElement, b: CollectionElement) => {
  return a.type === b.type && a.name === b.name && a.position === b.position && a.property === b.property;
};

interface Props {
  entityId: string;
  element: CollectionElement;
  formik: any;
  elements: CollectionElement[];
  editable: boolean;
  isForm?: boolean;
}

function SingleInfoModalElement({ entityId, element, formik, elements, editable, isForm = false }: Props) {
  const key = element.property;
  const width = '80%';
  const { bool, datetime, file, multi_file, multi_rel, multi_role, multi_select, multi_string, rel, role, select } =
    ELEMENT_TYPE_ID;

  if (element.type === multi_string) {
    return (
      <div className={classNames(containerClasses, 'multi!')}>
        <ElementLabel text={element.name} />
        <TableMultiCell
          tagsValues={formik.values[key]}
          editiable={editable}
          key={key}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          submitFormik={formik.handleSubmit}
          isModal
          width={width}
          minWidth={width}
        />
      </div>
    );
  }
  if (element.type === datetime) {
    return (
      <div className={classNames(containerClasses, 'multi!')}>
        <ElementLabel text={element.name} />
        <TableDateTimeCell
          element={element}
          submitFormik={formik.handleSubmit}
          formikProperty={element.property}
          setFormikValue={formik.setFieldValue}
          formikValue={formik.values[element.property]}
          key={element.property}
          editable
          width={width}
          minWidth={width}
        />
      </div>
    );
  }
  if (element.type === multi_select || element.type === multi_role) {
    const el = elements.find((ele) => isEqual(ele, element));
    const options = el?.options || [];
    return (
      <div className={containerClasses}>
        <ElementLabel text={element.name} />
        <TableDropdownCell
          options={options}
          dropdownOptions={formik.values[key]}
          editable={editable}
          key={key}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          submitFormik={formik.handleSubmit}
        />
      </div>
    );
  }
  if (element.type === select || element.type === role) {
    const el = elements.find((ele) => isEqual(ele, element));
    const options = el?.options || [];
    return (
      <div className={containerClasses}>
        <ElementLabel text={element.name} />
        <TableDropdownCell
          options={options}
          dropdownOptions={formik.values[key]}
          key={key}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          submitFormik={formik.handleSubmit}
          single
          editable={editable}
        />
      </div>
    );
  }
  if (element.type === rel) {
    return (
      <div className={containerClasses}>
        <ElementLabel text={element.name} />
        <TableRelCell
          element={element}
          submitFormik={formik.handleSubmit}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          formikValue={formik.values[key]}
          key={key}
          editiable={editable}
          errors={null}
          isForm={isForm}
          width={width}
          minWidth={width}
        />
      </div>
    );
  }
  if (element.type === multi_rel) {
    return (
      <div className={containerClasses}>
        <ElementLabel text={element.name} />
        <TableMultiRelCell
          element={element}
          submitFormik={formik.handleSubmit}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          formikValue={formik.values[key]}
          key={key}
          editiable={editable}
          width={width}
          minWidth={width}
          isModal
        />
      </div>
    );
  }
  if (element.type === file) {
    return (
      <div className={attachmentContainerClasses}>
        <ElementLabel text={element.name} />
        <TableAttachmentRow
          queueId={`entity-${entityId}-single-upload-${element.property}`}
          submitFormik={formik.handleSubmit}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          file={formik.values[key] || {}}
          key={key}
          isModal
          isForm={false}
          editable={editable}
          width={width}
          minWidth={width}
        />
      </div>
    );
  }
  if (element.type === multi_file) {
    return (
      <div className={attachmentContainerClasses}>
        <ElementLabel text={element.name} />
        <MultiAttachmentRow
          queueId={`entity-${entityId}-multi-upload-${element.property}`}
          submitFormik={formik.handleSubmit}
          formikProperty={key}
          setFormikValue={formik.setFieldValue}
          formikValue={formik.values[key]}
          key={key}
          isModal
          editable={editable}
          width={width}
          minWidth={width}
        />
      </div>
    );
  }
  if (element.type === bool) {
    return (
      <div className={containerClasses}>
        <ElementLabel text={element.name} />
        <CheckboxCell
          submitFormik={formik.handleSubmit}
          setFormikValue={formik.setFieldValue}
          key={key}
          width={width}
          minWidth={width}
          placeholder={element.name}
          formikValue={formik.values[element.property]}
          editable
          formikProperty={element.property}
        />
      </div>
    );
  }
  return (
    <div className={containerClasses}>
      <ElementLabel text={element.name} />
      <TableCell
        submitFormik={formik.handleSubmit}
        formikProperty={key}
        placeholder={`Enter ${element.name}`}
        setFormikValue={formik.setFieldValue}
        formikValue={formik.values[key]}
        editiable={editable}
        key={key}
        width={width}
        minWidth={width}
      />
    </div>
  );
}

SingleInfoModalElement.defaultProps = {
  isForm: false,
};

export default SingleInfoModalElement;
