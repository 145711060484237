/* eslint-disable @typescript-eslint/no-explicit-any */
export enum TriggerTypes {
  Db = 'db',
  Email = 'email',
  Http = 'http',
  Timer = 'timer',
}

export enum DbEvents {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum TimerIntervals {
  Minutes = 'minutes',
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export enum DataTypes {
  Array = 'array',
  DateTime = 'datetime',
  Number = 'number',
  Json = 'json',
  MultiString = 'multi_string',
  String = 'string',
}

export enum ParamType {
  Query = 'query',
  Form = 'form',
  Json = 'json',
  Header = 'header',
  Body = 'body',
}

export enum SourceType {
  JsonPath = 'json_path',
  Static = 'static',
}

export enum AutomationStepType {
  HttpRequest = 'http_request',
  JsonMapper = 'json_mapper',
  Task = 'task',
}

export enum InputSourceType {
  PreviousStep = 'previous_step',
  Workflow = 'workflow',
}

export enum DaysOfWeek {
  Sunday = 1,
  Monday = 2,
  Tuesday = 3,
  Wednesday = 4,
  Thursday = 5,
  Friday = 6,
  Saturday = 7,
}

export interface AppOptions {
  id: string;
  type: string;
  name: string;
  logoUrl: string;
}

export interface Trigger<T> {
  type: TriggerTypes;
  config: T;
}

export interface TimerTrigger {
  interval: TimerIntervals;
  time: string;
  days_of_week: number[];
  days_of_month: number[];
  repeat_interval: number;
}

export interface EmailTrigger {
  address: string;
  white_list: string[];
}

export interface DBTrigger {
  app_id: string;
  collection_id: string;
  events: DbEvents[];
}

export interface Param {
  name: string;
  prop: string;
  data_type: DataTypes;
  param_type: ParamType;
  source_type: SourceType;
  value: any;
}

export interface HttpTrigger {
  app_id: string;
  http_endpoint_id: string;
  params: Param[];
}

export interface HttpEndpoint {
  app_id: string;
  name: string;
  path: string;
  description: string;
  method: HttpMethod;
  params: Param[];
}

export interface HttpRequest {
  app_id: string;
  integration_id: string;
  path: string;
  method: HttpMethod;
  params: Param[];
}

export interface JsonMap {
  from_prop: Param;
  to_prop: Param;
}

export enum OutputType {
  Object = 'object',
  Array = 'array',
  String = 'string',
  Number = 'number',
  Void = 'void',
}

export enum InputType {
  Object = 'object',
  Array = 'array',
}

export enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Post = 'POST',
  Delete = 'DELETE',
}

export interface JsonMapper {
  input_type: InputType;
  input_path: string;
  mappings: JsonMap[];
}

export interface Task {
  app_id: string;
  integration_id: string;
  task_identifier: string;
  name: string;
  description: string;
  output_type: OutputType;
  output_params: Param[];
  default_config: Param[];
}

export interface TaskConfig {
  task_id: string;
  config: Param[];
}

export interface EmailConfig {
  recipients: string[];
  reply_to_sender: boolean;
  success_template_id: string;
  success_params: Param[];
  failure_template_id: string;
  failure_params: Param[];
}

export interface EmailTemplate {
  name: string;
  template_url: string;
  available_params: Param[];
}
