/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth0Client } from '@auth0/auth0-spa-js';
import React, { createContext, useEffect, useMemo, useReducer } from 'react';
import { decodeToken } from 'react-jwt';

import { auth0Config, LOCAL_STORAGE_TOKEN_KEY } from '../config';
import { ALLCOLLECTIONS, ENTITYURL, SIDEBAR } from '../constants/core.contants';
import { removeValueFromSessionStorage } from '../utils/core.utils';
import Analytics from '../utils/analytics';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const DASHBOARD_SIDEBAR = 'dashboard-sidebar';

interface InitialState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | any;
}

interface UserToken {
  orgs: string[];
  permissions: string[];
  scope: string;
  sub: string;
}

const initialState: InitialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const hopperLogo = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/613fc0f54822219d0fed2893_hopper-color.svg';
const logo = process.env.REACT_APP_LOGO_URL || hopperLogo;
const title = process.env.REACT_APP_LOGIN_TITLE || 'Welcome to Hopper';
const description = process.env.REACT_APP_LOGIN_DESCRIPTION || 'Sign In to continue to dashboard';

export const auth0Client = new Auth0Client({
  client_id: auth0Config.clientId || '',
  domain: auth0Config.domain || '',
  audience: auth0Config.audience || '',
  scope: auth0Config.scope || '',
  redirect_uri: window.location.origin,
  cacheLocation: 'localstorage',
  logo_url: logo,
  page_title: title,
  page_description: description,
});

const reducer = (state: InitialState, action: { type: string; payload?: any }): InitialState => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }
  if (action.type === SIGN_IN) {
    const { user } = action.payload;
    return { ...state, isAuthenticated: true, user };
  }
  if (action.type === SIGN_OUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();
        const token = await auth0Client.getTokenSilently();
        window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);

        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const userId = user?.sub;
          if (userId) Analytics.identify(userId);
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: user || null },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated, user: null },
          });
        }
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: { isAuthenticated: false, user: null },
        });
      }
    };
    initialize();
  }, []);

  const signIn = async () => {
    const loginRedirect = await auth0Client?.loginWithRedirect();
    return loginRedirect;
  };

  const onloadLogin = async (): Promise<any> => {
    const redirectResult = await auth0Client.handleRedirectCallback();
    const isAuthenticated = await auth0Client?.isAuthenticated();
    if (isAuthenticated) {
      const user = await auth0Client?.getUser();
      const token = await auth0Client.getTokenSilently();
      window.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
      const userId = user?.sub;
      if (userId) Analytics.identify(userId);
      dispatch({ type: SIGN_IN, payload: { user: user || null } });
      window.removeEventListener('load', onloadLogin);
      return redirectResult;
    }
    return window.removeEventListener('load', onloadLogin);
  };

  if (window.location.href.includes('code')) window.addEventListener('load', onloadLogin);

  const signOut = (): void => {
    auth0Client?.logout({ localOnly: true });
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    window.localStorage.removeItem(DASHBOARD_SIDEBAR);
    removeValueFromSessionStorage(SIDEBAR);
    removeValueFromSessionStorage(ALLCOLLECTIONS);
    removeValueFromSessionStorage(ENTITYURL);
    removeValueFromSessionStorage('organisation');
    dispatch({ type: SIGN_OUT });
  };

  const getToken = (): any | null => {
    const accessToken = window.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    if (accessToken) {
      const token: UserToken | null = decodeToken(accessToken);
      return token;
    }
    return null;
  };

  const providerValue: any = useMemo(
    () => ({
      ...state,
      method: 'auth0',
      user: {
        id: state?.user?.sub,
        avatar: state?.user?.picture,
        email: state?.user?.email,
        displayName: state?.user?.nickname,
        role: 'user',
      },
      getToken,
      signIn,
      signOut,
    }),
    [state]
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
}

export { AuthContext, AuthProvider };
