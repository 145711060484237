import React from 'react';
import { Link } from 'react-router-dom';

const AUTOMATIONS_PAGE = '/automations';

function Page404() {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="text-center">
        <h1 className="text-9xl font-bold mb-6">404</h1>
        <p className="text-3xl mb-3">Page not found.</p>
        <p className="text-2xl mb-7">The page you are looking for might have been removed.</p>
        <Link className="btn py-3" to={AUTOMATIONS_PAGE}>
          Homepage
        </Link>
      </div>
    </div>
  );
}

export default Page404;
