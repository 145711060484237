/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { UsersIcon } from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/24/outline';
import { Automation, AutomationStep } from '../../../redux/slices/automation.models';
import Spinner from '../../../components/shared/Spinner';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

const LOGO_URL = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/6418aca4cdaacc41798a7b5e_Hopper.png';

function RunButton({ isLoading, handleRun }: { isLoading: boolean; handleRun: () => void }) {
  return (
    <button
      type="button"
      onClick={() => handleRun()}
      className="ml-3 inline-flex items-center rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
    >
      {isLoading ? (
        <Spinner size="small" />
      ) : (
        <div className="flex">
          Run
          <PlayIcon className="h-5 w-5 text-white ml-1" aria-hidden="true" />
        </div>
      )}
    </button>
  );
}

export default function ListItem({ automation }: { automation: Automation }) {
  const { orgCode } = useParams();
  const features = useFeatureFlags();
  const isEditorEnabled = features.automation_editor?.is_enabled || false;
  const { entity_id: id, data } = automation;
  const { is_trigger: isTrigger } = data;
  const teams = data.teams || [];
  const steps = data.automation_steps || [];
  const [isLoading] = useState(false);
  const handleRun = async () => {};
  return (
    <li key={id}>
      <div className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div className="truncate">
              <div className="flex text-sm">
                {isEditorEnabled ? (
                  <Link to={`/${orgCode}/automations/${id}`}>
                    <p className="truncate font-medium text-blue-600 hover:text-blue-900">{data.name}</p>
                  </Link>
                ) : (
                  <p className="truncate font-medium text-blue-600">{data.name}</p>
                )}
              </div>
              <div className="mt-2 flex">
                <div className="flex items-center text-sm text-gray-500">
                  <UsersIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  {teams.map((team) => (
                    <p
                      key={`team-${team}`}
                      className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800 mr-2"
                    >
                      {team}
                    </p>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
              <div className="flex -space-x-1 overflow-hidden">
                {steps.map((step: AutomationStep) => {
                  const name = 'App name';
                  return (
                    <img
                      key={`automation-step-${step.entity_id}`}
                      className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
                      src={LOGO_URL}
                      alt={name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            {isTrigger ? <RunButton isLoading={isLoading} handleRun={handleRun} /> : <div className="md:w-24" />}
            {/* <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
          </div>
        </div>
      </div>
    </li>
  );
}
