/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { updateEntity, selectCollectionUrl, isEditable, setSelectedEntity } from '../../redux/slices/database';
import SingleInfoModal from './SingleInfoModal';
import useAppDispatch from '../../hooks/useAppDispatch';
import { notify } from '../../components/shared/Notification';
import { ServerError } from '../../redux/slices/core.models';
import { CollectionElement } from '../../redux/models/database.model';
import { getInitalValues } from '../../redux/slices/database.entity.utils';
import { Entity } from '../../redux/models/auth.models';

interface Props {
  entity: Entity;
  elements: CollectionElement[];
  isOpen: boolean;
  onClose: () => void;
}

// Add selected entity to redux
function EditEntityModal({ entity, elements, isOpen, onClose }: Props) {
  const dispatch = useAppDispatch();
  const collectionUrl = useSelector(selectCollectionUrl);
  const [isInFlight, setInFlight] = useState(false);
  const vals = entity ? getInitalValues(entity, elements) : {};
  const [initialValues, setInitialValues] = useState(vals);
  const editiable = useSelector(isEditable);
  const entityId = entity.entity_id;

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!isEqual(initialValues, values) && entity && !isInFlight) {
        const updatedEntityData = { ...entity, data: values };
        try {
          setInFlight(true);
          const response = await HopperServiceApiProvider.updateEntity(
            `${collectionUrl}/${entityId}`,
            updatedEntityData
          );
          if (response.status < 300) {
            const { data } = response;
            const updatedValues = getInitalValues(data.data, elements);
            setInitialValues(updatedValues);
            dispatch(updateEntity(data.data));
            dispatch(setSelectedEntity(data.data));
          } else {
            if (response.status >= 500) {
              const error = ServerError;
              notify("Couldn't update item", error.message, 'error');
            } else {
              notify("Couldn't update item", '', 'error');
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            const errorMessage = `Unable to update to item`;
            // eslint-disable-next-line no-console
            console.error(errorMessage);
          }
        } finally {
          setInFlight(false);
        }
      }
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {entity && (
        <SingleInfoModal
          inputValues={elements}
          open={isOpen}
          onClose={onClose}
          formik={formik}
          entityId={entity.entity_id}
          collectionUrl={collectionUrl}
          editable={editiable}
        />
      )}
    </form>
  );
}

export default EditEntityModal;
