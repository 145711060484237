/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
export enum FormType {
  DateTime = 'datetime',
  Embedded = 'embedded',
  Int = 'int',
  MultiSelect = 'multi_select',
  MultiString = 'multi_string',
  Rel = 'rel',
  Select = 'select',
  String = 'string',
}

export interface FormOption {
  color: string | null;
  display_value: string;
  enum_value: string;
}

export interface Dependency {
  editable: boolean;
  field_name: string;
  required: boolean;
  values: Array<any>;
  visible: boolean;
}

export interface RelConfig {
  collection_display_prop: string;
  collection_id: string;
  collection_prop: string | null;
}

export interface FormField {
  dependencies: Dependency[];
  editable: boolean;
  embedded_dynamic_form_id?: string;
  format: any;
  inherits_from_field: any;
  name: string;
  options: FormOption[] | null;
  precision: number | null;
  prop: string;
  rel_config?: RelConfig;
  rel_filter_prop: string | null;
  required: boolean;
  type: FormType;
  unit: string | null;
  value: any;
  visible: boolean;
}

export interface Form {
  fields: FormField[];
  name: string;
  target_entity: string;
}

export const getFieldValue = (field: FormField) => {
  if (field.type === FormType.Select) {
    return field.value.enum_value;
  }
  return field.value;
};
