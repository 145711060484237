/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { allRel, loadRelData, selectRelApps } from '../../../../redux/slices/database';
import RelationModal from '../../../Modal/RelationModal';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import HopperServiceApiProvider from '../../../../services/hopperServiceApiProvider';
import FormErrorMessage from '../../../Form/FormErrorMessage';
import { checkForName } from '../../../../utils/core.utils';
import 'react-datepicker/dist/react-datepicker.css';
import { getEntityDisplayName } from '../../../../redux/slices/database.utils';
import { DEFAULT_REQUEST_BODY } from '../../../../redux/slices/collection.models';
import { notify } from '../../../../components/shared/Notification';
import { Entity } from '../../../../redux/models/auth.models';
import { CollectionElement } from '../../../../redux/models/database.model';

interface Props {
  element: CollectionElement;
  formikValue: any;
  formikProperty: string;
  errors?: any;
  isForm: boolean;
  editiable: boolean;
  width: string;
  minWidth: string;
  setFormikValue: (prop: string, entity: Entity) => void;
  submitFormik: () => void;
}

function TableRelCell({
  element,
  formikValue,
  width,
  minWidth,
  setFormikValue,
  formikProperty,
  submitFormik,
  errors,
  isForm = false,
  editiable,
}: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const rels = useSelector(allRel);
  const relId = element.collection_id || '';
  const apps = useSelector(selectRelApps);
  const baseUrl = apps[relId]?.data?.base_url;
  const currentEntity = rels[element.collection_id || ''] || {};
  const path = currentEntity?.value?.path || '';
  const relUrl = `${baseUrl}${path}`;
  const isRelLoaded = currentEntity.dataLoaded;
  const name = getEntityDisplayName(currentEntity, formikValue?.data) || checkForName(formikValue?.data);
  const description = formikValue?.data?.description;
  const displayName = name || description || (formikValue === null && '+') || (isForm ? '+' : 'no name');
  const selectedOptions = formikValue ? [formikValue] : [];
  const { displayName: tagDisplayProp } = currentEntity;

  const handleSelect = (item: Entity) => {
    setFormikValue(formikProperty, item);
    if (!isForm) {
      submitFormik();
    }
  };

  const handleClick = async () => {
    setOpen(true);
    if (!isRelLoaded) {
      try {
        const response = await HopperServiceApiProvider.getEntityData(relUrl, DEFAULT_REQUEST_BODY);
        const entities = response.data.data.items || [];
        dispatch(loadRelData(element.collection_id, entities));
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = `Couldn't load records. ${error.message}. Please contact support if the problem persists.`;
          notify('Error loading', errorMessage, 'error');
        }
      }
    }
  };

  return (
    <div className="table-cell-row relative hover:border-2 hover:border-cell-border" style={{ width, minWidth }}>
      <div onClick={handleClick} className="table-inner-cell cursor-pointer">
        <span
          className={`bg-gray-200 hover:bg-white w-full break-words px-2 py-1 text-cell-input-text text-sm min-h-[25px] min-w-[25px] flex items-center bg-gray-200 ${
            formikValue === null ? '' : 'bg-cell-content'
          }  rounded-sm bg-gray-200`}
        >
          <span className="whitespace-nowrap truncate">{displayName}</span>
        </span>
      </div>
      {errors && <FormErrorMessage error={errors} />}
      {open && editiable && (
        <RelationModal
          element={element}
          relUrl={relUrl}
          handleSelect={handleSelect}
          isRelLoaded={isRelLoaded}
          rels={currentEntity}
          open={open}
          setOpen={setOpen}
          multiValues={selectedOptions}
          displayName={tagDisplayProp}
          isForm={false}
          multi={false}
          handleXClick={() => {}}
          submitFormik={() => {}}
        />
      )}
    </div>
  );
}

TableRelCell.defaultProps = {
  errors: null,
};

export default TableRelCell;
