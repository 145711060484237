/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { allRel, loadRelData, selectRelApps } from '../../../../redux/slices/database';
import RelationModal from '../../../Modal/RelationModal';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import HopperServiceApiProvider from '../../../../services/hopperServiceApiProvider';
import FormErrorMessage from '../../../Form/FormErrorMessage';

import 'react-datepicker/dist/react-datepicker.css';
import { DEFAULT_REQUEST_BODY } from '../../../../redux/slices/collection.models';
import PopupContainer from '../../../../components/popup/PopupContainer';
import { Entity } from '../../../../redux/models/auth.models';
import { notify } from '../../../../components/shared/Notification';

const buildContainerClasses = (isModal: boolean) => {
  const modal = isModal ? 'flex-wrap' : 'flex-nowrap';
  return `flex ${modal} overflow-hidden`;
};

interface Props {
  formikValue: any;
  setFormikValue: any;
  formikProperty: any;
  submitFormik: any;
  errors?: any;
  isForm?: any;
  editiable: any;
  element: any;
  width: any;
  minWidth: any;
  isModal?: boolean;
}

function TableMultiRelCell({
  formikValue,
  setFormikValue,
  formikProperty,
  submitFormik,
  errors,
  isForm = false,
  editiable,
  element,
  width,
  minWidth,
  isModal,
}: Props) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const rels = useSelector(allRel);
  const apps = useSelector(selectRelApps);
  const relId = element.collection_id || '';
  const baseUrl = apps[relId]?.data?.base_url;
  const currentEntity = rels[element.collection_id];
  const { path } = currentEntity.value;
  const relUrl = `${baseUrl}${path}`;
  const isRelLoaded = currentEntity.dataLoaded;

  const data = Array.isArray(formikValue) ? formikValue : [];
  const [multiValues, setMultiValues] = useState(data);
  const valData = isModal ? multiValues : multiValues.slice(0, 3);
  const { displayName } = currentEntity;
  const timerId = useRef<NodeJS.Timeout>();
  const [showPopup, setShowPopup] = useState(false);
  const handlePopupClick = () => {
    setShowPopup(true);
  };

  const handleSelect = (item: any) => {
    const newArr = [...multiValues, item];
    setFormikValue(formikProperty, newArr);
    setMultiValues((prev: any) => [...prev, item]);
  };

  const handleXClick = (idx: number) => {
    const newArr = multiValues.filter((ele: any, newIdx: any) => newIdx !== idx);
    setMultiValues(newArr);
    setFormikValue(formikProperty, newArr);
    clearTimeout(timerId.current);
    if (!isForm) {
      timerId.current = setTimeout(() => {
        submitFormik();
      }, 1500);
    }
  };

  const handleClick = async () => {
    setOpen(true);
    if (!isRelLoaded) {
      try {
        const response = await HopperServiceApiProvider.getEntityData(relUrl, DEFAULT_REQUEST_BODY);
        const entities = response.data.data.items || [];
        dispatch(loadRelData(element.collection_id, entities));
      } catch (error) {
        if (error instanceof Error) {
          const errorMessage = `${error.message}. Please contact support if the problem persists.`;
          notify(`Couldn't load ${element.name}`, errorMessage, 'error');
        }
      }
    }
  };

  useEffect(() => {
    if (Array.isArray(formikValue) && formikValue.length !== multiValues.length) {
      setMultiValues(formikValue);
    }
  }, [formikValue, multiValues]);

  return (
    <div
      className="table-cell-row relative hover:border-2 hover:border-cell-border overflow-clip"
      style={{ minWidth, width }}
    >
      <div>
        {multiValues && (
          <div className={buildContainerClasses(isModal || false)}>
            {valData.map((ele: Entity, idx: number) => {
              const name = ele.data[displayName];
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={name + idx}
                  className="hover:border text-cell-input-text m-1 px-2 py-1 rounded-sm flex items-center bg-gray-200 hover:bg-white"
                >
                  <span className="whitespace-nowrap truncate text-sm">{name}</span>
                  {editiable && (
                    <FontAwesomeIcon
                      fixedWidth
                      height={24}
                      width={24}
                      icon={faXmark}
                      className="pr-1 cursor-pointer"
                      onClick={() => handleXClick(idx)}
                    />
                  )}
                </div>
              );
            })}
            {multiValues.length > 3 && !isModal && (
              <div className="mr-4 popup-button-container">
                <button
                  type="button"
                  onClick={handlePopupClick}
                  className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  ...
                </button>
              </div>
            )}
            {editiable && (
              <div onClick={handleClick} className="table-inner-cell cursor-pointer">
                +
              </div>
            )}
            {showPopup && (
              <PopupContainer open={showPopup} setOpen={setShowPopup}>
                {multiValues.map((ele: any, idx: number) => {
                  const name = ele.data[displayName];
                  return (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={name + idx}
                      className="border rounded-md mb-5 py-3 px-3 hover:border-blue-500 relative"
                    >
                      <span className="whitespace-nowrap truncate text-sm">{name}</span>
                      {editiable && (
                        <FontAwesomeIcon
                          fixedWidth
                          height={24}
                          width={24}
                          icon={faXmark}
                          className="pr-1 cursor-pointer absolute top-0 right-[-5px]"
                          onClick={() => handleXClick(idx)}
                        />
                      )}
                    </div>
                  );
                })}
              </PopupContainer>
            )}

            {errors && <FormErrorMessage error={errors} />}
          </div>
        )}

        {open && editiable && (
          <RelationModal
            element={element}
            relUrl={relUrl}
            handleSelect={handleSelect}
            isRelLoaded={isRelLoaded}
            rels={currentEntity}
            open={open}
            setOpen={setOpen}
            multiValues={multiValues}
            displayName={displayName}
            handleXClick={handleXClick}
            submitFormik={submitFormik}
            isForm={isForm}
            multi
          />
        )}
      </div>
    </div>
  );
}

TableMultiRelCell.defaultProps = {
  isForm: false,
  errors: null,
  isModal: false,
};

export default TableMultiRelCell;
