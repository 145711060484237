/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from '../../../utils/tailwind';

interface Props {
  name: string;
  prop: string;
  type: string;
  placeholder: string;
  value: string;
  errorMessage: string;
  isValid: boolean;
  handleChange: (prop: string, value: string) => void;
}

export default function TextInput({
  name,
  type,
  placeholder,
  isValid,
  value,
  prop,
  errorMessage,
  handleChange,
}: Props) {
  const validColours =
    'text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-500';
  const inValidColours =
    'text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500';
  const classes = 'block w-full rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6 pl-2';
  return (
    <div className="min-w-0 flex-1 mt-4">
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {name}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type={type}
          name={name}
          id={name}
          className={classNames(isValid ? validColours : inValidColours, classes)}
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleChange(prop, e.target.value)}
          aria-invalid="true"
          aria-describedby="email-error"
        />
        {!isValid ? (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : null}
      </div>
      {!isValid ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMessage}
        </p>
      ) : null}
    </div>
  );
}
