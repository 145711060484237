/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { deleteSingleDisplayEntity, selectEntityTitle } from '../../redux/slices/database';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import useAppDispatch from '../../hooks/useAppDispatch';
import SingleInfoModalElement from './SingleInfoModalElement';
import { CollectionElement } from '../../redux/models/database.model';
import { Group } from '../../redux/models/auth.models';
import { notify } from '../../components/shared/Notification';
import { selectGroupsList } from '../../redux/slices/groups';
import EntityModal from './EntityModal';

interface Props {
  open: boolean;
  formik: any;
  inputValues: any;
  collectionUrl: string;
  entityId: string;
  editable: boolean;
  onClose: () => void;
}

function SingleInfoModal({ open, formik, inputValues, collectionUrl, entityId, editable, onClose }: Props) {
  const dispatch = useAppDispatch();
  const title = useSelector(selectEntityTitle);
  const groups = useSelector(selectGroupsList);
  const [permissionSelected, setPermissionSelected] = useState<Group | null>(null);
  const [roleId] = formik.values.role_ids || [];

  const handleDelete = async () => {
    const check = window.confirm('Are you sure you want to delete your data?');
    if (check) {
      try {
        await HopperServiceApiProvider.deleteSingleEntityItem(`${collectionUrl}/${entityId}`);
        dispatch(deleteSingleDisplayEntity(entityId));
      } catch (error) {
        const errorMessage = `Unable to delete record from the database`;
        notify(errorMessage, 'Please contact support if the issue persists', 'error');
      } finally {
        onClose();
      }
    }
  };

  const handleAddGroup = (group: Group) => {
    if (group) {
      setPermissionSelected(group);
      formik.setFieldValue('role_ids', [group.id]);
      formik.handleSubmit();
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
    onClose();
  };

  useEffect(() => {
    if (roleId && groups) {
      const firstGroup = groups.find((group) => group.id === roleId);
      if (firstGroup) {
        setPermissionSelected(firstGroup);
      }
    }
  }, [roleId, groups]);

  return (
    <EntityModal
      open={open}
      title={title}
      selectedGroup={permissionSelected}
      editable={editable}
      isSaving={false}
      canDelete={editable}
      onSave={handleSave}
      onAddGroup={handleAddGroup}
      onClose={onClose}
      onDelete={handleDelete}
    >
      {inputValues.map((element: CollectionElement) => {
        return (
          <SingleInfoModalElement
            entityId={entityId}
            key={element.name}
            element={element}
            formik={formik}
            elements={inputValues}
            editable={editable}
          />
        );
      })}
    </EntityModal>
  );
}

export default SingleInfoModal;
