/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { initialPageLoad } from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';

import { getUserOrgByCode, persistOrganisation, selectOrganisation } from '../../redux/slices/organisation';
import { isAutomationsLoading, loadAutomations, selectAutomationsList } from '../../redux/slices/automations';
import { getValueFromSessionStorage } from '../../utils/core.utils';
import { ALLCOLLECTIONS, SIDEBAR } from '../../constants/core.contants';
import AutomationContainer from './AutomationContainer';
import useFeatureFlags from '../../hooks/useFeatureFlags';

function AutomationList() {
  const { orgCode }: any = useParams();
  const features = useFeatureFlags();
  const navigate = useNavigate();
  const isLoading = useSelector(isAutomationsLoading);
  const org = useSelector(selectOrganisation);
  const organisationId = org?.entity_id || '';
  const automations = useSelector(selectAutomationsList);
  const dispatch: any = useAppDispatch();

  const isEditorEnabled = features.automation_editor?.is_enabled || false;
  const sidebarItem = getValueFromSessionStorage(SIDEBAR);
  const collections = getValueFromSessionStorage(ALLCOLLECTIONS);

  useEffect(() => {
    const loadOrg = async (code: string) => {
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        dispatch(initialPageLoad());
      } else {
        navigate('/organisations');
      }
    };
    if (org.entity_id === '') {
      loadOrg(orgCode);
    }
  }, [org, orgCode, dispatch, navigate]);

  useEffect(() => {
    if (!collections || !sidebarItem) {
      dispatch(initialPageLoad());
    }
  }, [collections, sidebarItem, dispatch]);

  useEffect(() => {
    if (organisationId !== '') {
      dispatch(loadAutomations(organisationId, isEditorEnabled));
    }
  }, [dispatch, organisationId, isEditorEnabled]);
  return <AutomationContainer isLoading={isLoading} automations={automations} />;
}

export default AutomationList;
