/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import Spinner from '../components/shared/Spinner';
import {
  isDisplayLoaded,
  selectDisplayTableData,
  isListView,
  selectDatabaseError,
  isDatabaseError,
  isDatabaseEmpty,
  selectCanLoadMore,
  selectRequest,
  selectCollectionUrl,
  loadFilters,
  resetDisplayGroup,
  isEditable,
  selectDatabaseViewType,
  setSelectedEntity,
  setGroup,
  selectCollection,
  updateViewType,
  resetPage,
} from '../redux/slices/database';
import GroupedTableContainer from './Body/GroupedTableContainer/GroupedTableContainer';
import ErrorPage from '../components/errors/ErrorPage';
import Header from './Header/Header';
import { NetworkError } from '../redux/slices/core.models';
import LoadMoreButton from './Body/LoadMoreButton';
import { CollectionRequest, DEFAULT_REQUEST_PAGINATION, QueryOperator } from '../redux/slices/collection.models';
import useAppDispatch from '../hooks/useAppDispatch';
import AddEntityModal from './Modal/AddEntityModal';
import FormButton from './Body/Footer/FormButton';
import { COLLECTION_VIEW_TYPE, CollectionElement, TableData } from '../redux/models/database.model';
import KanbanBoard from '../components/kanban/KanbanBoard';
import GridView from '../components/grid/GridView';
import EditEntityContainer from './Modal/EditEntityContainer';
import { getDefaultGroupBy } from '../redux/slices/database.utils';
import Permission from '../components/guards/Permissions';
import { ResourcePermission } from '../redux/models/permissions.models';
import DataGrid from './DataGrid/DataGrid';
import CalendarView from '../components/calendar/CalendarView';
import { createSearchFilters } from '../utils/filter.utils';
import EntityApiProvider from '../services/EntityApiProvider';

const { TABLE, BOARD, GRID, CALENDAR } = COLLECTION_VIEW_TYPE;

interface TableProps {
  search: string;
  displayData: TableData;
  isEmpty: boolean;
  onEdit: () => void;
}

function Table({ search, displayData, isEmpty, onEdit }: TableProps) {
  const isList = useSelector(isListView);
  return (
    <div>
      <div>
        {isList ? (
          <DataGrid search={search} displayData={displayData} isEmpty={isEmpty} onEdit={onEdit} />
        ) : (
          <GroupedTableContainer onEdit={onEdit} />
        )}
      </div>
    </div>
  );
}

function TableSpinner() {
  return (
    <div className="w-full h-96 flex justify-center items-center">
      <Spinner />
    </div>
  );
}

function DbError({ error }: { error: NetworkError | null }) {
  return (
    <div className="w-full mt-24 flex justify-center items-center">
      <ErrorPage error={error} />
    </div>
  );
}

function MainContainer() {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlEntityId = searchParams.get('entity_id');
  const collection = useSelector(selectCollection);
  const collectionUrl = useSelector(selectCollectionUrl);
  const isUrlModal = urlEntityId !== null;
  const isLoaded = useSelector(isDisplayLoaded);
  const isError = useSelector(isDatabaseError);
  const editable = useSelector(isEditable);
  const viewType = useSelector(selectDatabaseViewType);
  const requestFilters = useSelector(selectRequest);
  const url = useSelector(selectCollectionUrl);
  const canLoadMore = useSelector(selectCanLoadMore);
  const error = useSelector(selectDatabaseError);
  const displayData = useSelector(selectDisplayTableData);
  const isEmpty = useSelector(isDatabaseEmpty);
  const [search] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(isUrlModal);
  const isTableView = viewType === TABLE;
  const isGridView = viewType === GRID;
  const isKanbanView = viewType === BOARD;
  const isCalendarView = viewType === CALENDAR;
  const elements = displayData.columns || [];
  const resourceId = collection?.resource_id || '';
  const WritePermissions = {
    [resourceId]: ResourcePermission.Write,
  };
  const handleEditEntity = () => {
    setEditModalOpen(true);
  };

  const handleCloseEditEntity = () => {
    setSearchParams({});
    setEditModalOpen(false);
    dispatch(setSelectedEntity(null));
  };

  const resetFilters = () => {
    const filters: CollectionRequest = {
      ...requestFilters,
      filters: [],
      pagination: {
        ...requestFilters.pagination,
        page: 0,
      },
      operator: QueryOperator.And,
    };
    dispatch(loadFilters(url, filters));
  };

  const resetGroup = () => {
    dispatch(resetDisplayGroup());
    const filters: CollectionRequest = {
      ...requestFilters,
      pagination: DEFAULT_REQUEST_PAGINATION,
    };
    delete filters.grouping;
    dispatch(loadFilters(url, filters));
  };

  const selectGroup = (element: CollectionElement) => {
    dispatch(setGroup(element, displayData, url, requestFilters));
  };

  const handleSelectView = async (view: string) => {
    if (view === BOARD) {
      const element = getDefaultGroupBy(displayData.columns || []);
      if (element) await selectGroup(element);
    }
    if (!collection) return;
    dispatch(updateViewType(view, collection));
  };

  const debouncedResults = useMemo(() => {
    const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const searchFilters = createSearchFilters(e.target.value, collection?.elements || []);
        const filters: CollectionRequest = {
          ...searchFilters,
          grouping: requestFilters.grouping,
        };
        const response = await EntityApiProvider.getEntityData(collectionUrl, filters);
        const data = EntityApiProvider.parseResponse(response);
        const totalCount = data.total_count;
        dispatch(resetPage({ data, filters: requestFilters, totalCount }));
      } catch (err) {
        console.log(err);
      }
    };
    return debounce(handleSearch, 300);
  }, [collectionUrl, dispatch, collection, requestFilters]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <section className="static">
      <Header
        viewType={viewType}
        selectView={handleSelectView}
        selectGroup={selectGroup}
        resetGroup={resetGroup}
        resetFilters={resetFilters}
        handleSearch={debouncedResults}
      />
      {isLoaded ? null : <TableSpinner />}
      {isError && isLoaded ? <DbError error={error} /> : null}
      <div className="mt-36">
        {!isError && isLoaded && isTableView ? (
          <Table search={search} displayData={displayData} isEmpty={isEmpty} onEdit={handleEditEntity} />
        ) : null}
        {!isError && isLoaded && isKanbanView ? <KanbanBoard onEdit={handleEditEntity} /> : null}
        {!isError && isLoaded && isCalendarView ? (
          <CalendarView displayData={displayData} search={search} onEdit={handleEditEntity} />
        ) : null}
        {!isError && isLoaded && isGridView && (
          <GridView search={search} data={displayData} isEmpty={isEmpty} onEdit={handleEditEntity} />
        )}
      </div>
      {canLoadMore && isLoaded && !isError && <LoadMoreButton />}
      {!addModalOpen && editable && (
        <Permission resources={WritePermissions}>
          <FormButton setShowForm={setAddModalOpen} showForm={addModalOpen} />
        </Permission>
      )}
      {addModalOpen && (
        <Permission resources={WritePermissions}>
          <AddEntityModal open={addModalOpen} setOpen={setAddModalOpen} />
        </Permission>
      )}
      {!isError && isLoaded && urlEntityId && (
        <EditEntityContainer
          entityId={urlEntityId}
          elements={elements}
          isOpen={editModalOpen}
          onClose={handleCloseEditEntity}
        />
      )}
    </section>
  );
}

export default MainContainer;
