import React from 'react';
import { FormOption } from '../form.models';

interface Props {
  name: string;
  selectedId: string;
  options: FormOption[];
  onChange: (option: FormOption | null | undefined) => void;
}

const getOptionId = (option: FormOption) => option.enum_value;

function FormSelectInput({ name, selectedId, options, onChange }: Props) {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const option = options.find((element) => getOptionId(element) === id);
    onChange(option);
  };
  return (
    <select
      id={`select-element-${name}`}
      name={name}
      value={selectedId}
      onChange={handleChange}
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      {options.map((element) => {
        const id = getOptionId(element);
        return (
          <option key={id} value={id}>
            {element.display_value || ''}
          </option>
        );
      })}
    </select>
  );
}

export default FormSelectInput;
