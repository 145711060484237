/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ListItem from './ListItem';

export default function AutomationsList({ automations }: any) {
  const data = automations || [];
  return (
    <div className="overflow-hidden bg-white shadow">
      <ul className="divide-y divide-gray-200">
        {data.map((automation: any) => (
          <ListItem key={automation.entity_id} automation={automation} />
        ))}
      </ul>
    </div>
  );
}
