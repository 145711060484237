/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Automation } from '../../../redux/slices/automation.models';
import { selectOrganisation } from '../../../redux/slices/organisation';
import TriggerLayoutItem from './TriggerLayoutItem';
import StepLayoutItem from './StepLayoutItem';

interface Props {
  automation: Automation;
}

export default function FormLayout({ automation }: Props) {
  const org = useSelector(selectOrganisation);
  const [openStep, setOpen] = useState(0);
  const apps = org.data.apps || [];
  const steps = automation.data.automation_steps || [];
  const trigger = automation.data.automation_trigger || {};
  return (
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-1 w-full">
      <TriggerLayoutItem
        automationId={automation.entity_id}
        step={trigger}
        order={1}
        apps={apps}
        openStep={openStep}
        setOpen={setOpen}
      />
      {steps.map((step, idx) => (
        <StepLayoutItem
          key={step.entity_id}
          automationId={automation.entity_id}
          step={step}
          order={idx + 2}
          apps={apps}
          openStep={openStep}
          setOpen={setOpen}
        />
      ))}
    </div>
  );
}
