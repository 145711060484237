export interface Update {
  date: string;
  img: string | null;
  version: string;
  title: string;
  details: string;
  updates: string[];
}

export const updates: Update[] = [
  {
    date: '2023-10-16',
    img: null,
    version: '1.15.0',
    title: 'Permissions Update',
    details: '',
    updates: ['Users should no longer have to log out and in for new permissions to be applied'],
  },
  {
    date: '2023-10-03',
    img: null,
    version: '1.14.11',
    title: 'Collections Update',
    details: '',
    updates: ['Fix Calendar view rendering related collection records'],
  },
  {
    date: '2023-10-03',
    img: null,
    version: '1.14.10',
    title: 'Collections Update',
    details: '',
    updates: ["Fix: Fix related collection records printing 'Not Set' in the downloaded csv export"],
  },
  {
    date: '2023-09-02',
    img: null,
    version: '1.14.9',
    title: 'Collections Update',
    details: '',
    updates: ['New: Add calendar view to databases', 'Update: dynamic search field on collections'],
  },
  {
    date: '2023-08-25',
    img: null,
    version: '1.14.8',
    title: 'Reports Update',
    details: '',
    updates: ['New: Add dashboard for database reports', 'Update: fix styling on collection editor'],
  },
  {
    date: '2023-08-24',
    img: null,
    version: '1.14.7',
    title: 'Collections Update',
    details: '',
    updates: ['Update: Collection table upgrades'],
  },
  {
    date: '2023-08-23',
    img: null,
    version: '1.14.6',
    title: 'Collections Update',
    details: '',
    updates: ['New: Add grid view to collections', 'New: Add board view to collections'],
  },
  {
    date: '2023-08-20',
    img: null,
    version: '1.14.5',
    title: 'Permissions Update',
    details: '',
    updates: ['Update: For editability on permissions'],
  },
  {
    date: '2023-08-07',
    img: null,
    version: '1.14.4',
    title: 'Collections Update',
    details: '',
    updates: [
      'Fix: Filter records from other apps by related items',
      'Fix: fix for selecting multi select options on new records',
    ],
  },
  {
    date: '2023-07-18',
    img: null,
    version: '1.14.3',
    title: 'Collections Update',
    details: '',
    updates: [
      'Update: Link directly to records by copy pasting the url in your browser address bar',
      'Added a fix for badge names of related database items in the table view',
    ],
  },
  {
    date: '2023-07-15',
    img: null,
    version: '1.14.2',
    title: 'Collections Update',
    details: '',
    updates: ['Add fix for multi select elements in collections'],
  },
  {
    date: '2023-05-09',
    img: null,
    version: '1.14.1',
    title: 'Collections From Multiple Apps',
    details: '',
    updates: ['Side navigation bar now contains collections from multiple apps'],
  },
  {
    date: '2023-04-27',
    img: null,
    version: '1.13.2304272',
    title: 'Permissions Updates',
    details: '',
    updates: ['Updates for group permissions', 'Add query string support to collections urls'],
  },
  {
    date: '2023-04-27',
    img: null,
    version: '1.13.230427',
    title: 'Group Collection Elements',
    details:
      'Two new element types have been added. Group Select and Group Multi Select. These work the same as option select and multi select',
    updates: [],
  },
  {
    date: '2023-04-26',
    img: null,
    version: '1.12.230426',
    title: 'Collection Rels Filters Updates',
    details: '',
    updates: ['Search database when filters for related database items'],
  },
  {
    date: '2023-04-25',
    img: null,
    version: '1.12.230425',
    title: 'Uploader Improvements',
    details: '',
    updates: [
      'Drag and drop multiple files at a time',
      'Download pdfs when clicked',
      'Download images when modal opened',
      'Add caching support for downloaded files',
      'fix for filtering collections by number elements',
    ],
  },
];

export const VERSION_NO = updates[0].version;
