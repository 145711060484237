/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable  */
import { createSlice } from '@reduxjs/toolkit';
import { ALLCOLLECTIONS, ENTITYURL, SIDEBAR } from '../../constants/core.contants';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { getValueFromSessionStorage, saveValueToSessionStorage } from '../../utils/core.utils';
import { NetworkError, NotFound, PermissionsError, ServerError } from './core.models';
import {
  CollectionElement,
  InitialPageLoadAction,
  DatabaseState,
  Organisation,
  TableData,
  COLLECTION_VIEW_TYPE,
  DEFAULT_ENTITY_SELECTION_STATE,
  SelectedEntityState,
  CollectionData,
  Collection,
} from '../models/database.model';
import {
  checkRel,
  setRel,
  createSidebarData,
  createEmptyFooter,
  sumRowData,
  validGroupByProperty,
  createGroupData,
  addListEntity,
  updateListEntity,
  addGroupedEntity,
  mergeListViewRows,
  mergeGroupedRows,
  getCanLoadMore,
  resetGroupedData,
  getRelEntities,
} from './database.utils';
import { CollectionRequest, DEFAULT_REQUEST_BODY, DEFAULT_REQUEST_PAGINATION } from './collection.models';
import { Entity } from '../models/auth.models';
import { AppState } from '../models/app.models';
import { DATABASE } from './slices';
import EntityApiProvider from '../../services/EntityApiProvider';
import Analytics from '../../utils/analytics';
import CollectionApiProvider from '../../services/CollectionApiProvider';
import { getLocalOrganisation, saveLocalOrganisation } from './organisation';

const SLICE_NAME = DATABASE;

const initialState: DatabaseState = {
  isDatabaseLoaded: false,
  isPageLoaded: false,
  isDisplayLoaded: false,
  isAllLoaded: false,
  errorOnLoadingData: false,
  error: null,
  isRel: false,
  canLoadMore: false,
  collectionUrl: '',
  collection: null,
  editable: false,
  displayGroup: {
    group: null,
    data: [],
  },
  displayEntity: {
    title: '',
    columns: [],
    rows: [],
    footerRows: [],
    isOpen: true,
  },
  request: DEFAULT_REQUEST_BODY,
  allRel: {},
  relApps: {},
  selectionState: DEFAULT_ENTITY_SELECTION_STATE,
  viewType: COLLECTION_VIEW_TYPE.TABLE,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsPageLoaded(state, action) {
      state.isPageLoaded = action.payload;
    },
    setIsDatabaseLoaded(state, action) {
      state.isDatabaseLoaded = action.payload;
    },
    setIsDisplayLoaded(state, action) {
      state.isDisplayLoaded = action.payload;
    },
    setIsRel(state, action) {
      state.isRel = action.payload;
    },
    setEditable(state, action) {
      state.editable = action.payload;
    },
    setCollectionPath(state, action) {
      state.collectionUrl = action.payload;
    },
    setCollection(state, action) {
      const collection: CollectionData = action.payload;
      state.collection = collection;
      state.viewType = collection.view_type || COLLECTION_VIEW_TYPE.TABLE;
    },
    setGroupData(state, action) {
      state.displayGroup.data = action.payload;
    },
    setDisplayEntityColumns(state, action) {
      state.displayEntity.columns = action.payload;
    },
    setFilters(state, action) {
      state.request = action.payload;
    },
    setDisplayEntityRows(state, action) {
      const { data, totalCount } = action.payload;
      state.displayEntity.rows = data;
      state.displayEntity.count = totalCount
      state.canLoadMore = getCanLoadMore(state, state.request);
    },
    resetPage(state, action) {
      const { data, totalCount, filters } = action.payload;
      const { grouping } = filters;
      if (grouping) {
        state.displayGroup.data = resetGroupedData(state, data);
      } else {
        state.displayEntity.rows = data.items;
      }
      state.request = filters;
      state.displayEntity.count = totalCount;
      state.canLoadMore = getCanLoadMore(state, state.request);
    },
    setNextPage(state, action) {
      const { data, filters, totalCount } = action.payload;
      const { grouping } = filters;
      state.request = {
        ...filters,
      };
      if (grouping) {
        state.displayGroup.data = mergeGroupedRows(state, data);
      } else {
        state.displayEntity.rows = mergeListViewRows(state, data);
      }
      state.displayEntity.count = totalCount;
      state.canLoadMore = getCanLoadMore(state, filters);
    },
    setDisplayEntityFooter(state, action) {
      state.displayEntity.footerRows = action.payload;
    },
    setDisplayGroup(state, action) {
      const { element, data } = action.payload;
      state.displayGroup = {
        group: element,
        data: [...data],
      };
      state.request.grouping = {
        field: `data.${element.property}`
      };
    },
    resetDisplayGroup(state) {
      state.request.grouping = undefined;
      state.displayGroup = {
        group: null,
        data: [],
      };
    },
    setAllRel(state, action) {
      state.allRel = action.payload;
    },
    setRelApps(state, action) {
      state.relApps = action.payload;
    },
    setOneRel(state, action) {
      const { firstPayload, secondPayload } = action.payload;
      state.allRel = {
        ...state.allRel,
        [firstPayload]: { ...state.allRel[firstPayload], dataLoaded: true, data: secondPayload },
      };
    },
    setErrorOnLoadingData(state, action) {
      const { isError, error } = action.payload;
      state.errorOnLoadingData = isError;
      state.error = error;
    },
    addNewEntity(state, action) {
      const entities = addListEntity(state, action.payload);
      const groupData = addGroupedEntity(state, entities);
      state.displayEntity.rows = entities;
      if (groupData) {
        state.displayGroup = {
          ...state.displayGroup,
          data: [...groupData],
        };
      }
    },
    updateEntity(state, action) {
      const entities = updateListEntity(state, action.payload);
      const groupData = addGroupedEntity(state, entities);
      if (groupData) {
        state.displayGroup = {
          ...state.displayGroup,
          data: [...groupData],
        };
      }
      state.displayEntity.rows = entities;
    },
    deleteSingleDisplayEntity(state, action) {
      const entities = state.displayEntity.rows;
      state.displayEntity.rows = entities.filter((element) => element.entity_id !== action.payload);
    },
    resetDatabase(state, action) {
      state = {
        ...initialState,
      };
    },
    resetSelectedEntityState(state, action) {
      state.selectionState = DEFAULT_ENTITY_SELECTION_STATE;
    },
    setSelectedEntity(state, action) {
      state.selectionState.entity = action.payload;
    },
    setSelectedEntityState(state, action) {
      state.selectionState = action.payload;
    },
    setViewType(state, action) {
      state.viewType = action.payload;
    }
  },
});

export const {
  setAllRel,
  setCollection,
  setDisplayEntityColumns,
  setDisplayEntityRows,
  setDisplayEntityFooter,
  setCollectionPath,
  setDisplayGroup,
  deleteSingleDisplayEntity,
  resetDisplayGroup,
  setEditable,
  setErrorOnLoadingData,
  setFilters,
  setIsDisplayLoaded,
  setIsDatabaseLoaded,
  setIsPageLoaded,
  setIsRel,
  setNextPage,
  resetPage,
  addNewEntity,
  setOneRel,
  setRelApps,
  setViewType,
  updateEntity,
  setGroupData,
  resetDatabase,
  resetSelectedEntityState,
  setSelectedEntity,
  setSelectedEntityState,
} = slice.actions;

export const setError = (status: number, title: string) => async (dispatch: any) => {
  if (status === 403) {
    dispatch(setErrorOnLoadingData({ isError: true, error: PermissionsError }));
  } else if (status >= 500) {
    dispatch(setErrorOnLoadingData({ isError: true, error: ServerError }));
  } else {
    const err: NetworkError = {
      code: status,
      title,
      message: 'Please contact support if the problem persists.',
    };
    dispatch(setErrorOnLoadingData({ isError: true, error: err }));
  }
};

export const loadRelData: any = (id: string, entities: Entity[]) => (dispatch: any) => {
  dispatch(setOneRel({ firstPayload: id, secondPayload: entities }));
};

export const initialPageLoad = (): InitialPageLoadAction => async (dispatch) => {
  try {
    const data = getValueFromSessionStorage('organisation');
    const rowURL = data?.data.base_url;
    const sidebarData = createSidebarData(data);
    saveValueToSessionStorage(ALLCOLLECTIONS, data);
    saveValueToSessionStorage(SIDEBAR, sidebarData);
    saveValueToSessionStorage(ENTITYURL, rowURL);
  } catch (error) {
    if (error instanceof Error) {
      const notFound = {
        ...NotFound,
        title: "Couldn't load database. Please contact support if the problem persists.",
      };
      dispatch(setErrorOnLoadingData({ isError: true, error: notFound }));
    }
  } finally {
    dispatch(setIsPageLoaded(true));
  }
};

export const setGroup: any = (element: CollectionElement, displayData: TableData, path: string, request: CollectionRequest) => async (dispatch: any) => {
  try {
    dispatch(setIsDisplayLoaded(false));
    const groupBy = element.type === 'rel' ? element.rel_filter_prop : element.property;
    const req: CollectionRequest = {
      ...request,
      pagination: DEFAULT_REQUEST_PAGINATION,
      grouping: {
        field: `data.${groupBy}`,
      },
    };
    const response = await HopperServiceApiProvider.getEntityData(path, req);
    const data = createGroupData(displayData, element, response.data.data);
    dispatch(setDisplayGroup({ element, data }));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setIsDisplayLoaded(true));
  }
};

export const setPageSize: any = (pageSize: number, path: string, request: CollectionRequest) => async (dispatch: any) => {
  dispatch(setIsDisplayLoaded(false));
  try {
    const filters: CollectionRequest = {
      ...request,
      pagination: {
        page: 0,
        page_size: pageSize,
      },
    };
    const response = await EntityApiProvider.getEntityData(path, filters);
    if (response.status < 400) {
      const data = EntityApiProvider.parseResponse(response);
      const totalCount = data.total_count || 0;
      dispatch(resetPage({ data, totalCount, filters }));
    } else {
      dispatch(setError(response.status, `Couldn't load records.`));
    }
  } catch (error) {
    throw new Error("Couldn't set page size");
  } finally {
    dispatch(setIsDisplayLoaded(true));
  }
};

export const sortCollection: any = (element: CollectionElement, displayData: TableData, path: string, request: CollectionRequest) => async (dispatch: any) => {
  try {
    const req = {
      ...request,
      pagination: DEFAULT_REQUEST_PAGINATION,
      sorting: [{
        field: `data.${element.property}`,
        order: 'ASC',
      }],
    };
    const response = await HopperServiceApiProvider.getEntityData(path, req);
    const grouping = req.grouping;
    if (grouping) {
      const data = createGroupData(displayData, element, response.data.data);
      dispatch(setDisplayGroup({ element, data }));
    } else {
      const data = response.data.data.items || [];
      const totalCount = response.data.data.total_count || 0;
      dispatch(setDisplayEntityRows({ data, totalCount }));
    }
  } catch (error) {
    throw new Error("Couldn't sort collection");
  }
};

/**
 * Used when loading pages to set redux state. This lets inputs containing rel and multi_rel collection types http data.
 * @param organisation Organisation entity
 * @param elements Collection elements
 * @returns 
 */
export const loadEntityRelationships = (organisation: Organisation, elements: CollectionElement[]) => async (dispatch: any) => {
  const relExist = checkRel(elements);
  if (relExist) {
    const relObj = setRel(elements, organisation);
    dispatch(setAllRel(relObj));
    dispatch(setIsRel(true));
  }
  const relApps = getRelEntities(elements, organisation);
  if (Object.values(relApps).length) {
    dispatch(setRelApps(relApps));
  }
}

const configDisplayData: any = (collection: CollectionData) => async (dispatch: any) => {
  dispatch(setEditable(collection?.editable))
  const elements = collection?.elements || [];
  const footer = createEmptyFooter(elements);  
  dispatch(setDisplayEntityColumns(elements));
  dispatch(setDisplayEntityFooter(footer));
}

export const loadEntities = (baseUrl: string, path: string, filters: CollectionRequest) => {
  return HopperServiceApiProvider.getEntityData(`${baseUrl}${path}`, filters);
};

export const loadEntity: any = (path: string, collection: CollectionData, organisation: Organisation, filters: CollectionRequest) => async (dispatch: any) => {
  dispatch(setIsDisplayLoaded(false));
  dispatch(setErrorOnLoadingData({ isError: false, message: '' }));
  dispatch(setFilters(filters));
  dispatch(resetDisplayGroup());
  const response = await HopperServiceApiProvider.getEntityData(path, filters);
  try {
    if (response.status < 400) {
      dispatch(setCollectionPath(path));
      dispatch(loadEntityRelationships(organisation, collection?.elements || []));
      dispatch(configDisplayData(collection));
      const data = response.data.data;
      const totalCount = response.data.data.total_count || 0;
      dispatch(resetPage({ data, totalCount, filters }));
    } else {
      dispatch(setError(response.status, `Couldn't load ${collection.name}.`));
    }
  } catch (error) {
    const err: NetworkError = {
      code: response.status,
      title: `Couldn't load ${collection.name}.`,
      message: 'Please contact support if the problem persists.',
    };
    dispatch(setErrorOnLoadingData({ isError: true, error: err }));
  } finally {
    dispatch(setIsDisplayLoaded(true));
    dispatch(setIsDatabaseLoaded(true));
  }
};

export const saveEntity = (collectionUrl: string, entityId: string, body: Entity) => {
  const url = `${collectionUrl}/${entityId}`;
  return EntityApiProvider.updateEntity(url, body);
};

export const loadEntityById: any = (url: string) => async (dispatch: any) => {
  dispatch(setSelectedEntityState({
    entity: null,
    isError: false,
    error: null,
    isLoaded: false,
    isLoading: true,
  }));
  const response = await EntityApiProvider.getEntityById(url);
  try {
    if (response.status < 400) {
      const data = response.data.data;
      const state: SelectedEntityState = {
        entity: data,
        isError: false,
        error: null,
        isLoaded: true,
        isLoading: false,
      };
      dispatch(setSelectedEntityState(state));
    }
  } catch (error) {
    const err: NetworkError = {
      code: response.status,
      title: `Couldn't load item.`,
      message: 'Please contact support if the problem persists.',
    };
    dispatch(setSelectedEntityState({
      entity: null,
      isError: true,
      error: err,
      isLoaded: true,
      isLoading: false,
    }));
  }
};

export const loadFilters: any = (path: string, request: CollectionRequest) => async (dispatch: any) => {
  dispatch(setIsDisplayLoaded(false));
  dispatch(setErrorOnLoadingData({ isError: false, message: '' }));
  let status = 0;
  try {
    const response = await HopperServiceApiProvider.getEntityData(path, request);
    status = response.status;
    if (response.status < 400) {
      const data = response.data.data;
      const totalCount = response.data.data.total_count || 0;
      dispatch(resetPage({ data, totalCount, filters: request }));
    } else {
      dispatch(setError(response.status, `Couldn't load records.`));
    }
  } catch (error) {
    const err: NetworkError = {
      code: status,
      title: `Couldn't load records.`,
      message: 'Please contact support if the problem persists.',
    };
    dispatch(setErrorOnLoadingData({ isError: true, error: err }));
  } finally {
    dispatch(setIsDisplayLoaded(true));
  }
};

const updateOrg = (collection: CollectionData) => {
  const org = getLocalOrganisation();
  if (!org) return ;
  const updates: Organisation = {
    ...org,
    data: {
      ...org.data,
      apps: [...org.data.apps].map((app) => {
        if (app.entity_id === collection.app?.entity_id) {
          return {
            ...app,
            data: {
              ...app.data,
              collections: [...app.data.collections].map((collData) => {
                if (collData.resource_id === collection.resource_id) {
                  return collection;
                }
                return collData;
              }),
            },
          };
        }
        return app;
      }),
    },
  };
  saveLocalOrganisation(updates);
};

const updateCollection: any = (collection: Collection) => async (dispatch: any) => {
  const updatedView: CollectionData = {
    ...collection.data,
    entity_id: collection.entity_id,
  };
  updateOrg(updatedView);
  dispatch(configDisplayData(updatedView));
  dispatch(setCollection(updatedView));
}

export const updateViewType: any = (type: string, collection: Collection) => async (dispatch: any) => {
  dispatch(setViewType(type));
  try {
    const colResponse = await CollectionApiProvider.getCollectionById(collection.entity_id);
    if (colResponse.status === 200) {
      const updates: Collection = {
        ...colResponse.data.data,
        data: {
          ...colResponse.data.data.data,
          view_type: type,
        },
      };
      const response = await CollectionApiProvider.updateCollection(updates);
      if (response.status === 200) {
        updateCollection(response.data.data);
      };
    }
  } catch (error) {
    Analytics.capture(error);
  }
};

export const updateCollectionElements: any = (elements: CollectionElement[], collection: CollectionData) => async (dispatch: any) => {
  dispatch(setIsDisplayLoaded(false));
  try {
    const colResponse = await CollectionApiProvider.getCollectionById(collection.entity_id);
    if (colResponse.status === 200) {
      const updates: Collection = {
        ...colResponse.data.data,
        data: {
          ...colResponse.data.data.data,
          elements: elements,
        },
      };
      const response = await CollectionApiProvider.updateCollection(updates);
      if (response.status === 200) {
        dispatch(updateCollection(response.data.data));
      };
    }
  } catch (error) {
    Analytics.capture(error);
  } finally {
    dispatch(setIsDisplayLoaded(true));
  }
};

const databaseReducer = slice.reducer;
export default databaseReducer;
export const isPageLoaded = (state: AppState) => state[SLICE_NAME].isPageLoaded;
export const isRel = (state: AppState) => state[SLICE_NAME].isRel;
export const selectCollection = (state: AppState) => state[SLICE_NAME].collection;
export const selectCollectionUrl = (state: AppState): string => state[SLICE_NAME].collectionUrl;
export const selectSelectedEntity = (state: AppState) => state[SLICE_NAME].selectionState.entity;
export const selectSelectionState = (state: AppState) => state[SLICE_NAME].selectionState;
export const selectCanLoadMore = (state: AppState): boolean => state[SLICE_NAME].canLoadMore;
export const selectDisplayTableData = (state: AppState) => state[SLICE_NAME].displayEntity;
export const selectDisplayRow = (state: AppState) => state[SLICE_NAME].displayEntity.rows;
export const selectDisplayColumn = (state: AppState) => state[SLICE_NAME].displayEntity.columns;
export const selectEntityTitle = (state: AppState) => state[SLICE_NAME].collection?.name || '';
export const isDatabaseLoaded = (state: AppState) => state[SLICE_NAME].isDatabaseLoaded;
export const isDatabaseError = (state: AppState) => state[SLICE_NAME].errorOnLoadingData;
export const selectDatabaseError = (state: AppState) => state[SLICE_NAME].error;
export const isDisplayLoaded = (state: AppState): boolean => state[SLICE_NAME].isDisplayLoaded;
export const selectIsDatabaseLoaded = (state: AppState): boolean => state[SLICE_NAME].isDisplayLoaded;
export const isDatabaseEmpty = (state: AppState) => state[SLICE_NAME].displayEntity.rows.length === 0;
export const isEditable = (state: AppState) => state[SLICE_NAME].editable;
export const isEntitiesLoaded = (state: AppState) => state[SLICE_NAME].isAllLoaded;
export const allRel = (state: AppState) => state[SLICE_NAME].allRel;
export const selectRelApps = (state: AppState) => state[SLICE_NAME].relApps;
export const selectRelAppById = (state: AppState, relId: string) => {
  return selectRelApps(state)[relId];
};
export const isListView = (state: AppState): boolean => selectRequest(state).grouping === undefined;
export const selectDatabaseViewType = (state: AppState): string => state[SLICE_NAME].viewType;
export const selectDisplayGroup = (state: AppState) => state[SLICE_NAME].displayGroup;
export const selectRequest = (state: AppState): CollectionRequest => state[SLICE_NAME].request;
export const selectGroupByColumns = (state: AppState) => {
  return selectDisplayColumn(state).filter((item: CollectionElement) => validGroupByProperty(item));
};
export const isTableFooter = (state: AppState) => {
  const { columns } = state[SLICE_NAME].displayEntity;
  const el = columns.find((element) => element.type === 'number');
  return el;
};
export const selectDisplayTableFooterData = (state: AppState, item: CollectionElement) => {
  const { rows } = state[SLICE_NAME].displayEntity;
  return sumRowData(item, rows);
};
