/* eslint-disable import/no-cycle */
import React from 'react';
import { isEqual } from 'lodash';
import { FormField as FormFieldModel, Form as FormModel, getFieldValue } from './form.models';
import FormField from './FormField';

interface Props {
  form: FormModel;
  onFormChange: (form: FormModel) => void;
}

const isVisible = (form: FormModel, field: FormFieldModel) => {
  const dependencies = field.dependencies || [];
  return dependencies.some((dep) => {
    const matchingField = form.fields.find((fld) => fld.prop === dep.field_name);
    if (matchingField) {
      return dep.values.some((depVal) => isEqual(getFieldValue(matchingField), depVal));
    }
    return false;
  });
};

export default function Form({ form, onFormChange }: Props) {
  const fields = form.fields || [];
  const visible = fields.filter((field) => field.visible || isVisible(form, field));
  const handleChange = (field: FormFieldModel) => {
    const newForms = {
      ...form,
      fields: fields.map((fld) => {
        if (fld.name === field.name) {
          return field;
        }
        return fld;
      }),
    };
    onFormChange(newForms);
  };
  return (
    <div>
      <p>{form.name || ''}</p>
      {visible.map((field, idx) => (
        <FormField key={`${field.name}-${field.prop}-${field.prop}`} idx={idx} field={field} onChange={handleChange} />
      ))}
    </div>
  );
}
