import { axiosInstance } from '../utils/axios';
import HopperServiceConfigProvider from './hopperServiceConfigProvider';

class AutomationApiProvider {
  static async getAutomationDetails(automationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getAutomationDetails(automationId));
  }

  static getTrigger(automationId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getAutomationTrigger(automationId));
  }

  static getStep(automationId: string, stepId: string) {
    return axiosInstance.get(HopperServiceConfigProvider.getAutomationStep(automationId, stepId));
  }

  static create(name: string) {
    return axiosInstance({
      method: 'POST',
      url: HopperServiceConfigProvider.postAutomation(),
      data: {
        name,
        description: '',
        is_on: false,
        role_ids: [],
      },
      validateStatus: () => true,
    });
  }
}

export default AutomationApiProvider;
