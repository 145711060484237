/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { ThunkAction } from '@reduxjs/toolkit';
import { DataModel, EntityContainer, NetworkError } from '../slices/core.models';
import { CollectionRequest } from '../slices/collection.models';
import { Entity } from './auth.models';
import { App } from './app.models';

export const DISPLAY_TYPE = {
  LIST: 'list',
  GROUP: 'group',
};

export interface CollectionListResponse {
  id?: string;
  items: Entity[];
  total_count?: number;
}

export interface CollectionGroupResponse {
  items: CollectionListResponse[];
  total_count: number;
}

export type Columns = {
  name: string;
  position: number;
  property: string;
  type: string;
};

export type FooterCalculation = {
  name: string;
  id: string;
};

export type FooterItem = {
  title: string;
  detail: string | null;
  isActive: boolean;
  isSupported: boolean;
  calculations: FooterCalculation[];
};

export type TableData = {
  title: string;
  isOpen: boolean;
  columns: CollectionElement[];
  rows: Array<Entity>;
  footerRows: FooterItem[];
  count?: number;
};

export const COLLECTION_VIEW_TYPE = {
  CALENDAR: 'CALENDAR',
  GRID: 'GRID',
  BOARD: 'BOARD',
  TABLE: 'TABLE',
};

export type SelectedEntityState = {
  entity: Entity | null;
  error: NetworkError | null;
  isError: false;
  isLoaded: boolean;
  isLoading: boolean;
};

export const DEFAULT_ENTITY_SELECTION_STATE: SelectedEntityState = {
  entity: null,
  error: null,
  isError: false,
  isLoaded: false,
  isLoading: false,
};

export type DatabaseState = {
  allRel: EntityContainer<RelsContainer>;
  canLoadMore: boolean;
  collectionUrl: string;
  collection: CollectionData | null;
  displayGroup: {
    group: null | CollectionElement;
    data: Array<TableData>;
  };
  displayEntity: TableData;
  editable: boolean;
  errorOnLoadingData: boolean;
  error: NetworkError | null;
  isDatabaseLoaded: boolean;
  isDisplayLoaded: boolean;
  isPageLoaded: boolean;
  isAllLoaded: boolean;
  isRel: boolean;
  relApps: EntityContainer<App>;
  request: CollectionRequest;
  selectionState: SelectedEntityState;
  viewType: string;
};

export interface CollectionElementOption {
  color: string | null;
  id: string;
  name: string;
}

export interface CollectionElement {
  collection_id: null | string;
  element_display_prop: null | string;
  format: null | string;
  name: string;
  options: null | CollectionElementOption[];
  position: number;
  property: string;
  precision: number | null;
  required: boolean;
  type: string;
  rel_filter_prop: string | null;
  width?: number;
  visible?: boolean;
}

export interface CollectionData {
  app?: App;
  elements: Array<CollectionElement>;
  entity_id: string;
  name: string;
  editable: boolean;
  path: string;
  resource_id?: string;
  view_type?: string;
  visible: boolean;
}

export type Collection = DataModel<CollectionData>;

export interface Permission {
  access_level: string;
  id: string;
  permission: string;
}

export interface OrganisationData {
  admin_id: string;
  base_url: string;
  name: string;
  apps: App[];
  logo_url: string;
  members: Array<string>;
  organization_code: string;
  permissions: Permission[];
}

export type Organisation = DataModel<OrganisationData>;

export interface UserData {
  auth0_id: string;
  email: string;
  img_url: string;
  name: string;
  organization_ids: string;
  organizations: Organisation[];
  permissions: string[];
}

export type User = DataModel<UserData>;

export interface AuthState {
  isUsersLoaded: boolean;
  errorOnLoadingData: boolean;
  user: User | null;
}

export type InitialPageLoadAction = ThunkAction<Promise<void>, DatabaseState, null, any>;

export interface RelsContainer {
  value: CollectionData;
  dataLoaded: boolean;
  displayName: string;
  data: Entity[];
}
