/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { notify } from '../../components/shared/Notification';
import { initialCollectionState } from '../models/collection.models';
import { COLLECTIONS } from './slices';
import { AppState } from '../models/app.models';
import CollectionApiProvider from '../../services/CollectionApiProvider';
import { Collection } from '../models/database.model';

const SLICE_NAME = COLLECTIONS;

const slice = createSlice({
  name: COLLECTIONS,
  initialState: initialCollectionState,
  reducers: {
    setCollection(state, action) {
      state.collection = action.payload;
    },
    setIsLoading(state, action) {
      state.isCollectionLoading = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoadingData = action.payload;
    },
  },
});

export const { setCollection, setIsLoading, setErrorOnLoadingData } = slice.actions;

export const loadCollection = (collectionId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await CollectionApiProvider.getCollectionById(collectionId);
    dispatch(setCollection(data.data));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Collection. ${error.message}. Please contact support if the problem persists.`;
      notify('', errorMessage, 'info');
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const saveCollection = (collection: Collection) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await CollectionApiProvider.updateCollection(collection);
    dispatch(setCollection(data.data));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't save ${collection.data.name} collection. ${error.message}. Please contact support if the problem persists.`;
      notify('', errorMessage, 'info');
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

const collectionsReducer = slice.reducer;
export default collectionsReducer;
export const selectIsCollectionLoading = (state: AppState) => state[SLICE_NAME].isCollectionLoading;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].errorOnLoadingData;
export const selectCollection = (state: AppState) => state[SLICE_NAME].collection;
