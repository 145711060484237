/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
// import { useSelector } from 'react-redux';

import { AutomationStep } from '../../../redux/slices/automation.models';
import { App } from '../../../redux/models/app.models';
import { createTriggerAppOptions } from './triggers/trigger.utils';
import Form from './forms/Form';
import { Form as FormModel } from './forms/form.models';
import Analytics from '../../../utils/analytics';
import Spinner from '../../../components/shared/Spinner';
import { loadTrigger } from '../../../redux/slices/automation.details';

interface FormLayoutItemProps {
  automationId: string;
  step: AutomationStep;
  apps: App[];
  order: number;
  openStep: number;
  setOpen: React.Dispatch<React.SetStateAction<number>>;
}

const LOGO_URL = 'https://uploads-ssl.webflow.com/61368f7707cacb32aae7e93b/6418aca4cdaacc41798a7b5e_Hopper.png';

export default function TriggerLayoutItem({ automationId, step, order, apps, openStep, setOpen }: FormLayoutItemProps) {
  const appOptions = createTriggerAppOptions(apps);
  const [form, setForm] = useState<FormModel | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const app = appOptions.find((option) => option.type === step.data.type);
  const logoUrl = app?.logoUrl || LOGO_URL;
  console.log(openStep);
  const handleOpenSection = async () => {
    // get trigger info
    try {
      setOpen(order);
      setIsOpen(true);
      setIsLoading(true);
      const response = await loadTrigger(automationId);
      if (response.status === 200) setForm(response.data.data);
    } catch (error) {
      console.log(error);
      Analytics.capture(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    console.log(form);
  };

  return (
    <div
      key={step.entity_id}
      className="relative flex space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-gray-300 hover:border-gray-400"
    >
      <div className="flex-shrink-0">
        <img className="h-10 w-10 rounded-full" src={logoUrl} alt="" />
      </div>
      <div className="w-full">
        <button type="button" onClick={handleOpenSection} className="focus:outline-none items-center">
          <h2 className="text-xl font-semibold text-gray-900">{app?.name || ''}</h2>
          {/* <p className="truncate text-sm text-gray-500">{step.data.input_source || ''}</p> */}
        </button>
        {isOpen && (
          <div className="w-full mt-6">
            {isLoading && (
              <div className="w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {!isLoading && form && (
              <div>
                <Form form={form} onFormChange={(fm) => setForm(fm)} />
                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    onClick={() => setOpen(0)}
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleSave}
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
