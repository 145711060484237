/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import { Automation, AutomationStep, AutomationStepData } from './automation.models';
import HopperServiceConfigProvider from '../../services/hopperServiceConfigProvider';
import { notify } from '../../components/shared/Notification';
import { initialAutomationDetailsState, StepContainer } from '../models/automation.details.models';
import { EntityContainer } from './core.models';
import { AUTOMATION_DETAILS } from './slices';
import { AppState } from '../models/app.models';
import AutomationApiProvider from '../../services/AutomationApiProvider';

const SLICE_NAME = AUTOMATION_DETAILS;

type Container = EntityContainer<Automation>;

const createStepContainer = (automation: Automation) => {
  if (automation && automation.data) {
    const steps = automation.data.automation_steps || [];
    return steps.reduce((prev: StepContainer, curr: AutomationStep) => {
      return {
        ...prev,
        [curr.entity_id]: curr,
      };
    }, {});
  }
  return {};
};

const initialState = initialAutomationDetailsState;

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAutomation(state, action) {
      state.automation = action.payload;
      state.steps = createStepContainer(action.payload);
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsLoaded(state, action) {
      state.isLoaded = action.payload;
    },
    setStep(state, action) {
      state.selectedStepId = action.payload;
    },
    setErrorOnLoadingData(state, action) {
      state.errorOnLoad = action.payload;
    },
    resetAutomation(state) {
      state.automation = null;
    },
  },
});

export const { resetAutomation, setAutomation, setIsLoaded, setIsLoading, setErrorOnLoadingData, setStep } =
  slice.actions;

const mapAutomationData = (automations: Automation[], steps: AutomationStep[]) => {
  const stepObj = steps.reduce((prev: any, curr: AutomationStep) => {
    return {
      ...prev,
      [curr.entity_id]: curr,
    };
  }, {});
  const autoStepData = automations.map((ato: Automation) => {
    const atoSteps = ato.data.automation_steps || [];
    return {
      ...ato,
      data: {
        ...ato.data,
        steps: atoSteps.map((sp) => {
          if (stepObj[sp.entity_id]) {
            return stepObj[sp.entity_id];
          }
          return {};
        }),
      },
    };
  });
  const dataContainer: Container = {};
  autoStepData.forEach((automation: Automation) => {
    dataContainer[automation.entity_id] = automation;
  });
  return dataContainer;
};

export const getAutomationSteps = async (automations: Automation[]) => {
  const stepIds = automations.reduce((prev: any, curr: Automation) => {
    const updates = {
      ...prev,
    };
    const steps = curr.data.automation_steps || [];
    steps.forEach((step: AutomationStep) => {
      updates[step.entity_id] = step.entity_id;
    });
    return updates;
  }, {});
  const { data: stepData } = await HopperServiceApiProvider.getAutomationStepData(Object.values(stepIds));
  return mapAutomationData(automations, stepData.data);
};

export const loadAutomation: any = (automationId: string) => async (dispatch: any) => {
  dispatch(setIsLoading(true));
  dispatch(setErrorOnLoadingData(false));
  try {
    const { data } = await AutomationApiProvider.getAutomationDetails(automationId);
    const dataContainer = await getAutomationSteps([data?.data]);
    const [automation] = Object.values(dataContainer);
    dispatch(setAutomation(automation));
    dispatch(setIsLoaded(true));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Automations. ${error.message}. Please contact support if the problem persists.`;
      notify('', errorMessage, 'info');
    }
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const loadTrigger: any = (automationId: string) => {
  return AutomationApiProvider.getTrigger(automationId);
};

export const loadStep: any = (automationId: string, stepId: string) => {
  return AutomationApiProvider.getStep(automationId, stepId);
};

export const updateStep: any = (params: any, step: AutomationStep, automationId: string) => async (dispatch: any) => {
  try {
    const updates = {
      ...step,
      data: {
        ...step.data,
        params,
      },
    };
    const path = HopperServiceConfigProvider.automationStepPath();
    await HopperServiceApiProvider.updateHopperEntity(path, step.entity_id, updates);
    dispatch(setStep(''));
    dispatch(loadAutomation(automationId));
  } catch (error) {
    dispatch(setErrorOnLoadingData(true));
    if (error instanceof Error) {
      const errorMessage = `Couldn't get Automations. ${error.message}. Please contact support if the problem persists.`;
      notify('', errorMessage, 'info');
    }
  } finally {
    dispatch(setIsLoaded(true));
  }
};

export const runStep = async (step: AutomationStepData) => {
  await HopperServiceApiProvider.runStep(step);
};

const automationsReducer = slice.reducer;
export default automationsReducer;

export const selectAutomation = (state: AppState) => state[SLICE_NAME].automation;
export const selectIsAutomationLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;
export const selectIsAutomationLoading = (state: AppState) => state[SLICE_NAME].isLoading;
export const errorOnLoadingData = (state: AppState) => state[SLICE_NAME].errorOnLoad;
export const selectStepId = (state: AppState) => state[SLICE_NAME].selectedStepId;
export const selectIsStepSelected = (state: AppState) => selectStepId(state) !== '';
export const selectStep = (state: AppState, stepId: string) => state[SLICE_NAME].steps[stepId];
export const selectSteps = (state: AppState) => {
  const automation = selectAutomation(state);
  if (automation && automation.data) {
    return automation.data.automation_steps || [];
  }
  return [];
};
