/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import {
  initialPageLoad,
  isEditable,
  loadEntityById,
  loadEntityRelationships,
  selectSelectionState,
  setEditable,
  // setTitle,
} from '../../redux/slices/database';
import useAppDispatch from '../../hooks/useAppDispatch';
import Navbar from '../../components/navbar/Navbar';
import { getLocalOrganisation, getUserOrgByCode, persistOrganisation } from '../../redux/slices/organisation';
import { CollectionData, Organisation } from '../../redux/models/database.model';
import { loadGroups, selectGroupsList, selectIsGroupsLoaded, selectIsGroupsLoading } from '../../redux/slices/groups';

import EntityDetailsContainer from './components/EntityDetailsContainer';
import Spinner from '../../components/shared/Spinner';
import { getBaseUrl, getCollection } from '../../redux/slices/database.utils';

/**
 * TODO:
 * - ~Add permissions dropdown~
 * - ~Add edit permissions~
 * - Add delete
 */
function EntityDetails() {
  const dispatch: any = useAppDispatch();
  const { collectionPath, orgCode, entityId }: any = useParams();
  const { entity, isLoading, isLoaded } = useSelector(selectSelectionState);
  const isGroupsLoaded = useSelector(selectIsGroupsLoaded);
  const isGroupsLoading = useSelector(selectIsGroupsLoading);
  const groups = useSelector(selectGroupsList);
  const editable = useSelector(isEditable);
  const shouldFetch = !isLoaded && !isLoading;
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const org: Organisation = getLocalOrganisation();
  const handleCloseEntity = () => {};

  useEffect(() => {
    const setCollection = async (fetchedOrg: Organisation, col: CollectionData | undefined) => {
      const collectionElements = col?.elements || [];
      dispatch(setEditable(col?.editable || false));
      await dispatch(loadEntityRelationships(fetchedOrg, collectionElements));
    };
    const loadEntity = (fetchedOrg: Organisation, col: CollectionData) => {
      if (!shouldFetch) return;
      // dispatch(setTitle(col.name));
      /// update app base url here
      const appBaseUrl = getBaseUrl(fetchedOrg, col);
      if (appBaseUrl) {
        setBaseUrl(appBaseUrl);
        dispatch(loadEntityById(`${appBaseUrl}/${collectionPath}/${entityId}`));
      }
    };
    const loadOrg = async (code: string) => {
      if (!shouldFetch) return;
      const fetchedOrg = await getUserOrgByCode(code);
      if (fetchedOrg) {
        await dispatch(persistOrganisation(fetchedOrg));
        await dispatch(initialPageLoad());
        const col = getCollection(fetchedOrg, collectionPath);
        setCollection(fetchedOrg, collectionPath);
        if (col) loadEntity(fetchedOrg, col);
      }
    };
    if (!org) {
      loadOrg(orgCode);
    } else {
      const col = getCollection(org, collectionPath);
      setCollection(org, collectionPath);
      if (col) loadEntity(org, col);
    }
  }, [org, orgCode, collectionPath, dispatch, entityId, shouldFetch, navigate]);

  useEffect(() => {
    const loadedOrgCode = org?.data.organization_code;
    const groupData = groups || [];
    const isGroupEmpty = groupData.length === 0;
    if (loadedOrgCode && !isGroupsLoaded && isGroupEmpty && !isGroupsLoading) {
      dispatch(loadGroups(loadedOrgCode));
    }
  }, [org, dispatch, collectionPath, entityId, isGroupsLoaded, groups, isGroupsLoading]);
  console.log('editable: ', editable);
  return (
    <div className="min-h-full relative">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1 p-12">
          {isLoaded && entity && org && (
            <EntityDetailsContainer
              baseUrl={baseUrl}
              collectionPath={collectionPath}
              entity={entity}
              editable={editable}
              organisation={org}
              closeDetailView={handleCloseEntity}
            />
          )}
          {isLoading && (
            <div className="flex pt-32 w-full h-full items-center justify-center">
              <Spinner />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}

export default EntityDetails;
