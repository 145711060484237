/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Automation } from '../../redux/slices/automation.models';
import Sidebar from '../../components/sidebar/Sidebar';
import PageHeader from '../../components/headers/PageHeader';
import AutomationsList from './AutomationsList';
import Navbar from '../../components/navbar/Navbar';
import Spinner from '../../components/shared/Spinner';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import AddAutomationModal from './components/AddAutomationModal';

export default function AutomationContainer({
  isLoading,
  automations,
}: {
  isLoading: boolean;
  automations: Automation[];
}) {
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const features = useFeatureFlags();
  const isEditorEnabled = features.automation_editor?.is_enabled || false;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleAction = () => {
    setModalOpen(true);
  };
  const handleCreate = (automationId: string) => {
    console.log('handleCreate automationId: ', automationId);
    navigate(`/${orgCode}/automations/${automationId}`);
  };
  return (
    <div className="min-h-full">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-col lg:pl-64">
        <Navbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="flex-1">
          <PageHeader title="Automations" button={isEditorEnabled} buttonTitle="New" handleAction={handleAction} />
          <div className="">
            {isLoading && (
              <div className="flex pt-32 w-full h-full items-center justify-center">
                <Spinner />
              </div>
            )}
            {!isLoading ? <AutomationsList automations={automations} /> : null}
          </div>
        </main>
        {modalOpen && <AddAutomationModal open={modalOpen} setOpen={setModalOpen} onCreate={handleCreate} />}
      </div>
    </div>
  );
}
