import React from 'react';
import classNames from '../../../../utils/tailwind';
import { Colours, Hero } from '../../models';

export default function HeroLayout({
  colours,
  data,
  handleSignIn,
}: {
  colours: Colours;
  data: Hero;
  handleSignIn: () => Promise<void>;
}) {
  const btnStyles = `flex w-full items-center justify-center md:py-4 md:px-10 md:text-lg rounded-md border border-transparent px-8 py-3 text-base font-medium text-white`;
  return (
    <div className="relative bg-gray-50">
      <main className="lg:relative">
        <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">{data.header}</span>{' '}
              <span className={`block ${colours.textAccent} xl:inline`}>{data.headerSpan}</span>
            </h1>
            <p className="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              {data.subtext}
            </p>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <button
                  type="submit"
                  onClick={handleSignIn}
                  className={classNames(btnStyles, colours.buttonBg, colours.buttonHover)}
                >
                  {data.buttonText}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <img className="absolute inset-0 h-full w-full object-cover" src={data.imageUrl} alt="" />
        </div>
      </main>
    </div>
  );
}
