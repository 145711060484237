/* eslint-disable no-console */
import { App } from '../../../../redux/models/app.models';
import { Organisation } from '../../../../redux/models/database.model';
import {
  AutomationStepType,
  DBTrigger,
  DbEvents,
  HttpMethod,
  HttpTrigger,
  TriggerTypes,
  Param,
  DataTypes,
  ParamType,
  SourceType,
  HttpRequest,
  TaskConfig,
  InputSourceType,
  AppOptions,
} from './triggers.models';

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const createOptions = (options: string[]) => {
  return options.map((option) => ({
    id: option,
    name: capitalize(option),
  }));
};

const HopperApps: AppOptions[] = [
  {
    id: 'timer',
    type: TriggerTypes.Timer,
    name: 'Timer',
    logoUrl: 'https://static-00.iconduck.com/assets.00/timer-icon-2048x2048-l8if4eql.png',
  },
  {
    id: 'web_request',
    type: TriggerTypes.Http,
    name: 'Web Request',
    logoUrl:
      'https://w7.pngwing.com/pngs/154/132/png-transparent-webhook-application-programming-interface-hypertext-transfer-protocol-github-vmware-web-smallest-font-icon-line-text-logo-application-programming-interface.png',
  },
  {
    id: 'email',
    type: TriggerTypes.Email,
    name: 'Email',
    logoUrl: 'https://cdn-icons-png.flaticon.com/512/542/542638.png',
  },
];

export const createTriggerAppOptions = (apps: App[]) => {
  const appData: AppOptions[] = apps.map((app) => {
    return {
      id: app.entity_id,
      name: app.data.name,
      type: TriggerTypes.Db,
      logoUrl: app.data.logo_url,
    };
  });
  const triggers = HopperApps.filter((ap) => ap.id !== 'web_request');
  return [...triggers, ...appData].sort((a, b) => a.name.localeCompare(b.name));
};

export const httpMethodOptions = createOptions(Object.values(HttpMethod));
export const automationStepTypeOptions = createOptions(Object.values(AutomationStepType));
export const triggerTypeOptions = createOptions(Object.values(TriggerTypes));
export const inputSourceOptions = createOptions(Object.values(InputSourceType));
export const dbEventOptions = Object.values(DbEvents).map((option) => ({
  id: option,
  name: `${capitalize(option)} Record`,
}));

export const getDbEventOptions = (name: string) => {
  return Object.values(DbEvents).map((option) => ({
    id: option,
    name: `${name} ${capitalize(option)}d`,
  }));
};

interface OrgParams {
  app_id: string;
  collection_id: string;
}

export const getDefaultParam = (): Param => {
  return {
    name: '',
    prop: '',
    data_type: DataTypes.String,
    param_type: ParamType.Header,
    source_type: SourceType.Static,
    value: '',
  };
};

export const getDefaultOrgData = (org: Organisation) => {
  const orgData: OrgParams = {
    app_id: '',
    collection_id: '',
  };
  const apps = org?.data.apps || [];
  if (!apps.length) return orgData;
  const app = apps.find((ap) => ap.entity_id !== '');
  const collections = app?.data.collections || [];
  const collection = collections.find((col) => col.entity_id !== '');
  orgData.app_id = app?.entity_id || '';
  orgData.collection_id = collection?.entity_id || '';
  return orgData;
};

export const getDefaultDbTrigger = (org: Organisation): DBTrigger => {
  return {
    ...getDefaultOrgData(org),
    events: [DbEvents.Create],
  };
};

export const getDefaultHttpTrigger = (org: Organisation): HttpTrigger => {
  const orgData = getDefaultOrgData(org);
  return {
    app_id: orgData.app_id,
    http_endpoint_id: orgData.collection_id,
    params: [getDefaultParam()],
  };
};

export const getDefaultHttpRequest = (org: Organisation): HttpRequest => {
  const orgData = getDefaultOrgData(org);
  return {
    app_id: orgData.app_id,
    integration_id: orgData.collection_id,
    path: '',
    method: HttpMethod.Get,
    params: [],
  };
};

export const getDefaultTask = (): TaskConfig => {
  return {
    task_id: '',
    config: [getDefaultParam()],
  };
};
