/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const buildContainerClasses = (isInput: boolean, isModal: boolean) => {
  const input = isInput ? 'w-full' : 'hover:border-2 hover:border-cell-border';
  const modal = isModal ? 'flex-wrap' : 'flex-nowrap';
  return `table-inner-cell ${input} flex ${modal} overflow-clip`;
};

interface Props {
  tagsValues: any;
  width?: string;
  minWidth?: string;
  setFormikValue: any;
  editiable: boolean;
  submitFormik: any;
  formikProperty: any;
  isModal?: boolean;
}

function TableMultiCell({
  tagsValues,
  width,
  minWidth,
  setFormikValue,
  editiable,
  submitFormik,
  formikProperty,
  isModal,
}: Props) {
  const input = React.useRef<HTMLInputElement>(null);
  const [isInput, setIsInput] = useState(false);
  const items = Array.isArray(tagsValues) ? tagsValues : [];

  const handleClick = () => {
    if (editiable) {
      setIsInput(true);
    }
  };

  const handleBlur = () => {
    setFormikValue(formikProperty, tagsValues);
    submitFormik();
    setIsInput(false);
  };

  const handleKeyDown = (e: any) => {
    const typedInput = e.target.value;
    if (e.key === 'Enter' && typedInput !== '') {
      if (!tagsValues) {
        setFormikValue(formikProperty, [typedInput]);
      } else {
        const newItem = [...tagsValues, typedInput];
        setFormikValue(formikProperty, newItem);
      }
      input.current!.value = '';
    }
  };

  const handleXClick = (idx: number) => {
    const newArr = tagsValues.filter((ele: any, newIdx: any) => newIdx !== idx);
    setFormikValue(formikProperty, newArr);
    submitFormik();
  };

  return (
    <div className="table-cell-row relative" style={{ minWidth, width }}>
      <div onClick={handleClick} className={buildContainerClasses(isInput, isModal || false)}>
        {items.map((item: string, idx: number) => {
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={item + idx}
              className="hover:border text-cell-input-text m-1 px-2 py-1 rounded-full flex items-center bg-indigo-200 hover:bg-white"
            >
              <span className="whitespace-nowrap truncate text-xs font-medium text-indigo-900">{item}</span>
              {isInput && editiable && (
                <FontAwesomeIcon
                  fixedWidth
                  height={24}
                  width={24}
                  icon={faXmark}
                  className="pr-1 cursor-pointer"
                  onClick={() => handleXClick(idx)}
                />
              )}
            </div>
          );
        })}
        {isInput && editiable && (
          <input
            ref={input}
            className="w-full break-words text-sm outline-0	"
            type="text"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
          />
        )}
      </div>
    </div>
  );
}

TableMultiCell.defaultProps = {
  width: '100%',
  minWidth: '100%',
  isModal: false,
};

export default TableMultiCell;
