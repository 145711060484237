/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikValues, useFormik } from 'formik';
import { addNewEntity, selectCollectionUrl, selectDisplayColumn, selectEntityTitle } from '../../redux/slices/database';
import HopperServiceApiProvider from '../../services/hopperServiceApiProvider';
import useAppDispatch from '../../hooks/useAppDispatch';
import { CollectionElement } from '../../redux/models/database.model';
import { Group } from '../../redux/models/auth.models';
import { notify } from '../../components/shared/Notification';
import AddEntityElements from './AddEntityElements';
import EntityModal from './EntityModal';

/**
 * Check for empty string values on attachments and replace with null or remove from object
 * @param formValue formik value object
 * @param ele convert to collection element
 * @param roleIds selected group permissions
 * @returns entity data for POST /{entity}
 */
const submitValues = (formValue: FormikValues, ele: CollectionElement[], roleIds: string[]) => {
  const entityData = ele.reduce((entity, { property, type, required }) => {
    if (type === 'multi_file' || type === 'file') {
      const val = formValue[property];
      if (val === '') {
        return required
          ? {
              ...entity,
              [property]: null,
            }
          : {
              ...entity,
            };
      }
    }
    return {
      ...entity,
      [property]: formValue[property],
    };
  }, {});
  if (roleIds.length === 0) return entityData;
  return {
    ...entityData,
    role_ids: roleIds,
  };
};

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddEntityModal({ open, setOpen }: Props) {
  const title = useSelector(selectEntityTitle);
  const dispatch = useAppDispatch();
  const elements = useSelector(selectDisplayColumn);
  const collectionUrl = useSelector(selectCollectionUrl);
  const [resetArr, setResetArr] = useState<boolean>(false);
  const initialValues: any = {};
  const [isSaving, setIsSaving] = useState(false);
  const [permissionSelected, setPermissionSelected] = useState<Group | null>(null);

  const onCloseModal = (isOpen: boolean) => {
    setOpen(isOpen);
  };
  const missingRequirements: (formValue: FormikValues, ele: CollectionElement[]) => { [key: string]: string } = (
    formValue,
    ele
  ) => {
    return ele.reduce((invalid, { property, required }) => {
      if (required && (formValue[property] === undefined || formValue[property] === '')) {
        return { ...invalid, [property]: '' };
      }
      return invalid;
    }, {});
  };

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors = missingRequirements(values, elements);
      const errorKeys = Object.keys(errors);

      if (!errorKeys.length) {
        return undefined;
      }

      errorKeys.forEach((key) => {
        if (!errors[key]) {
          const errorColun = elements.find((ele) => ele.property === key);
          errors[key] = `"${errorColun?.name || 'This is'}" is required`;
        }
      });
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      setIsSaving(true);
      try {
        const roleIds = values.role_ids || [];
        const body: any = submitValues(values, elements, roleIds);
        const response = await HopperServiceApiProvider.addEntityData(`${collectionUrl}`, body);
        if (response.status < 300) {
          const { data } = response;
          dispatch(addNewEntity(data.data));
        } else {
          notify("Couldn't update item", 'Please contact support if the error persists.', 'error');
        }
      } catch (error) {
        const errorMessage = `Unable to add to items to Database`;
        notify(errorMessage, 'Please contact support if the error persists.', 'error');
      } finally {
        const valueKeys = Object.keys(values);
        valueKeys.forEach((ele) => {
          if (Array.isArray(values[ele])) {
            formik.setFieldValue(ele, []);
          }
        });
        setResetArr(true);
        setIsSaving(false);
        resetForm();
        onCloseModal(false);
      }
    },
    validateOnChange: false,
  });

  const handleAddGroup = (group: Group) => {
    if (group) {
      setPermissionSelected(group);
      formik.setFieldValue('role_ids', [group.id]);
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <EntityModal
      open={open}
      title={`Add ${title}`}
      selectedGroup={permissionSelected}
      editable
      isSaving={isSaving}
      onSave={handleSave}
      onAddGroup={handleAddGroup}
      onClose={handleClose}
      onDelete={async () => {}}
    >
      <AddEntityElements elements={elements} resetArr={resetArr} formik={formik} setResetArr={setResetArr} />
    </EntityModal>
  );
}

export default AddEntityModal;
