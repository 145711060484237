/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrganisation } from '../../../../../redux/slices/organisation';
import { RelConfig } from '../form.models';
import { Entity } from '../../../../../redux/models/auth.models';
import { App } from '../../../../../redux/models/app.models';
import { loadEntities } from '../../../../../redux/slices/database';
import { DEFAULT_REQUEST_BODY } from '../../../../../redux/slices/collection.models';
import EntityApiProvider from '../../../../../services/EntityApiProvider';

interface Props {
  name: string;
  value: Entity | null | undefined;
  config: RelConfig;
  onChange: (option: Entity | null | undefined) => void;
}

/**
 * - Find collection in user collections
 * - set base url from app
 * - Build options from app url + collection path
 */
function RelSelectInput({ name, value, config, onChange }: Props) {
  const org = useSelector(selectOrganisation);
  const [isLoaded, setIsLoaded] = useState(false);
  const [options, setOptions] = useState<Entity[]>([]);
  const configCollectionId = config?.collection_id || '';
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value;
    const option = options.find((entity) => entity.entity_id === id);
    onChange(option);
  };
  useEffect(() => {
    const getCollection = async (aps: App[]) => {
      let baseUrl = '';
      let path = '';
      aps.forEach(async (app) => {
        const collections = app.data.collections || [];
        const collection = collections.find((coll) => coll.entity_id === configCollectionId);
        if (collection) {
          baseUrl = app.data.base_url;
          path = collection.path;
          try {
            const response = await loadEntities(baseUrl, path, DEFAULT_REQUEST_BODY);
            const data = EntityApiProvider.parseResponse(response);
            setOptions(data.items);
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoaded(true);
          }
        }
      });
    };
    if (!isLoaded && org) {
      const apps = org?.data.apps || [];
      getCollection(apps);
    }
  }, [org, isLoaded, configCollectionId]);
  return (
    <select
      id={`select-element-${name}`}
      name={name}
      value={value?.entity_id}
      onChange={handleChange}
      className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
    >
      {options.map((entity) => {
        const id = entity.entity_id;
        const label = entity.data[config?.collection_display_prop] || '';
        return (
          <option key={id} value={id}>
            {label}
          </option>
        );
      })}
    </select>
  );
}

export default RelSelectInput;
